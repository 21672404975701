import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <filter x="-8.9%" y="-8.9%" width="117.9%" height="117.9%" filterUnits="objectBoundingBox" id="rankHighlight-filter-1">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0.854901961   0 0 0 0 0.294117647   0 0 0 0 0.968627451  0 0 0 1 0"
                               type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="rankHighlight-linearGradient-2">
                <stop stopColor="#EF82FC" stopOpacity="0.579190341" offset="0%"/>
                <stop stopColor="#FF328B" offset="100%"/>
            </linearGradient>
            <path
                d="M18,3 C18.5522847,3 19,3.44771525 19,4 L19,12.999 L25,13 C25.5522847,13 26,13.4477153 26,14 L26,24 C26,24.5522847 25.5522847,25 25,25 L10,25 C9.44771525,25 9,24.5522847 9,24 L9,4 C9,3.44771525 9.44771525,3 10,3 L18,3 Z"
                id="rankHighlight-path-3"/>
            <linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="rankHighlight-linearGradient-4">
                <stop stopColor="#24DFFF" stopOpacity="0.0317690122" offset="0%"/>
                <stop stopColor="#24DFFF" offset="100%"/>
            </linearGradient>
        </defs>
        <g id="icon/tabbar/rankHighlight-highlight" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g filter="url(#rankHighlight-filter-1)">
                <rect id="矩形" fill="#110036" opacity="0" x="0" y="0" width="28" height="28"/>
                <g id="形状结合">
                    <use fill="url(#rankHighlight-linearGradient-2)" fillRule="evenodd" xlinkHref="#rankHighlight-path-3"/>
                    <path stroke="#DA4BF7" strokeWidth="0.1"
                          d="M18,3.05 L10,3.05 C9.73766474,3.05 9.50016474,3.15633237 9.32824856,3.32824856 C9.15633237,3.50016474 9.05,3.73766474 9.05,4 L9.05,24 C9.05,24.2623353 9.15633237,24.4998353 9.32824856,24.6717514 C9.50016474,24.8436676 9.73766474,24.95 10,24.95 L25,24.95 C25.2623353,24.95 25.4998353,24.8436676 25.6717514,24.6717514 C25.8436676,24.4998353 25.95,24.2623353 25.95,24 L25.95,14 C25.95,13.7376647 25.8436676,13.5001647 25.6717514,13.3282486 C25.4998353,13.1563324 25.2623353,13.05 25,13.05 L18.95,13.0489917 L18.95,4 C18.95,3.73766474 18.8436676,3.50016474 18.6717514,3.32824856 C18.4998353,3.15633237 18.2623353,3.05 18,3.05 Z"
                          strokeLinejoin="square"/>
                </g>
                <path
                    d="M3,7 L15,7 L15,7 L15,24 C15,24.5522847 14.5522847,25 14,25 L3,25 C2.44771525,25 2,24.5522847 2,24 L2,8 C2,7.44771525 2.44771525,7 3,7 Z"
                    id="形状结合" fill="url(#rankHighlight-linearGradient-4)"/>
            </g>
        </g>
    </svg>
}






