import NavBar from "../../ui/NavBar";
import SafeAreaView from "../../ui/SafeAreaView";
import React, {useCallback, useState} from "react";
import {Form, FormItem, Button, Name} from './style'


export default function () {
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [desc, setDesc] = useState('')

    const updateName = useCallback((e) => {
        let value = e.target.value
        setName(value)
    }, [])

    const updateMobile = useCallback((e) => {
        let value = e.target.value
        setMobile(value)
    }, [])

    const updateDesc = useCallback((e) => {
        let value = e.target.value
        setDesc(value)
    }, [])

    const onSubmit = useCallback((e) => {
        e.preventDefault()
        if(!name) {
            alert('请输入联系人')
        } else if (!mobile) {
            alert('请输入手机号')
        } else {
            alert('预约成功')
        }
    }, [mobile, name, desc])

    return <SafeAreaView>
        <NavBar />
        <Name>波波映像</Name>
        <Form action='#' onSubmit={onSubmit}>
            <FormItem>
                <label>联系人</label>
                <input value={name} onChange={updateName} placeholder="请输入你的名字" type="text" />
            </FormItem>
            <FormItem>
                <label>联系电话</label>
                <input type={'tel'} value={mobile} onChange={updateMobile} placeholder="请输入你的电话号码" type="text" />
            </FormItem>
            <FormItem>
                <label>预约备注</label>
                <input value={desc} onChange={updateDesc} placeholder="请输入备注" type="text" />
            </FormItem>
            <Button onClick={onSubmit}>立即预约</Button>
        </Form>
    </SafeAreaView>
}