import React, {useCallback, useMemo, useRef, useState, useEffect, Fragment} from 'react'
import {TabItem} from './styles'
import {useThrottleFn} from 'react-use'

const getTabSize = (page, tabLength) => 100 / Math.min(page, tabLength)

export default function (props) {
    const {page = 5, initIndex = 0, onChange, tabs = [], renderTab, tabBarBackgroundColor, useUnderline = true} = props
    const [activeIndex, setIndex] = useState(initIndex)
    const itemRefs = useRef([])
    const startPoint = useRef({x: 0, y: 0})
    const prevDragPoint = useRef(null)
    const velocity = useRef(null)
    const _inAnimation = useRef(false)
    const isMoving = useRef(false)
    const offset = useRef({x: 0, y: 0})
    const wrapRef = useRef(null)
    const lineRef = useRef(null)

    const getTransformByIndex = useCallback((index) => {
        const activeDom = itemRefs.current[index]
        const wrapDom = wrapRef.current
        const parent = wrapDom.parentNode
        const clientWidth = window.innerWidth
        let _offset = 0
        if (activeDom && wrapDom) {
            let tabWidth = activeDom.offsetWidth / 2
            let tabLeft = activeDom.offsetLeft
            let wrapWidth = clientWidth / 2
            _offset = (tabLeft - wrapWidth) + tabWidth
            let maxOffset = wrapDom.scrollWidth - parent.offsetWidth
            _offset = Math.max(_offset, 0)
            _offset = Math.min(_offset, maxOffset)
            offset.current.x = -_offset
            _offset = (_offset / wrapDom.scrollWidth) * 100
        }
        return {
            value: `translate3d(-${_offset}%, 0, 0)`,
            index
        }
    }, [])

    const resetInertia = useCallback(() => {
        velocity.current = null
        prevDragPoint.current = null
    }, [])

    const collectInertia = useCallback((touches) => {
        const x = touches[0].pageX
        const y = touches[0].pageY
        if (prevDragPoint.current) {
            velocity.current = {
                x: x - prevDragPoint.current.x,
                y: y - prevDragPoint.current.y
            }
        }
        prevDragPoint.current = {x, y}
    }, [])

    const animate = useCallback((frameFn, options) => {
        const startTime = new Date().getTime();
        const {timeFn, callback, duration} = {
            timeFn: (p) => -Math.cos(p * Math.PI) / 2 + 0.5,
            callback: () => {
            },
            ...options
        };
        const renderFrame = () => {
            if (!_inAnimation.current) {
                return;
            }

            const frameTime = new Date().getTime() - startTime;
            let progress = frameTime / duration;

            if (frameTime >= duration) {
                frameFn(1)
                _inAnimation.current = false
                callback();
                wrapRef.current.style.setProperty('transform', `translate3d(${offset.current.x}px, 0, 0)`)
            } else {
                progress = timeFn(progress);
                frameFn(progress);
                wrapRef.current.style.setProperty('transform', `translate3d(${offset.current.x}px, 0, 0)`)
                requestAnimationFrame(renderFrame);
            }
        };
        _inAnimation.current = true;

        requestAnimationFrame(renderFrame);
    }, [])

    useEffect(() => {
        _inAnimation.current = false
        const tabDom = itemRefs.current[initIndex]
        const width = tabDom ? tabDom.offsetWidth : 0
        const left = tabDom ? tabDom.offsetLeft : 0
        const {value, index} = getTransformByIndex(initIndex, page, tabs)
        // if(lineRef.current) {
        //     lineRef.current.style.setProperty('width', `${width}px`)
        //     lineRef.current.style.setProperty('left', `${left}px`)
        // }
        wrapRef.current.style.setProperty('transition', 'transform .3s cubic-bezier(.35,0,.25,1)')
        wrapRef.current.style.setProperty('transform', value)
        setIndex(index)
    }, [initIndex, tabs.length, getTransformByIndex])

    const onClick = useCallback((item, index) => {
        _inAnimation.current = false
        const value = getTransformByIndex(index, page, tabs)
        if (wrapRef.current) {
            wrapRef.current.style.setProperty('transition', 'transform .3s cubic-bezier(.35,0,.25,1)')
            wrapRef.current.style.setProperty('transform', value)
        }
        if (typeof onChange === 'function') {
            onChange(index)
        }
        setIndex(index)
    }, [getTransformByIndex, page, tabs.length, onChange])


    const renderTabs = useMemo(() => {
        const size = getTabSize(page, tabs.length)
        return tabs.map((item, index) => {
            const active = activeIndex === index
            if (renderTab) {
                return <div key={index}
                            ref={el => itemRefs.current[index] = el}>{renderTab(item, active, onClick, index)}</div>
            }
            return <TabItem active={active} ref={el => itemRefs.current[index] = el} key={index}
                            onClick={() => onClick(item, index)}>{item.title}</TabItem>
        })
    }, [tabs.length, activeIndex, renderTab])

    const underLineStyle = {
        'position': 'absolute',
        'bottom': 0,
        boxSizing: 'border-box',
        border: '1px solid #108ee9',
        transition: 'top .3s cubic-bezier(.35,0,.25,1),left .3s cubic-bezier(.35,0,.25,1),color .3s cubic-bezier(.35,0,.25,1),width .3s cubic-bezier(.35,0,.25,1)'
    }

    const onTouchStart = useCallback((e) => {
        const touches = e.touches
        resetInertia()
        if (touches.length === 1) {
            startPoint.current = {
                x: touches[0].pageX,
                y: touches[0].pageY
            }
        }
    }, [resetInertia])

    const onTouchMove = useCallback((e) => {
        const touches = e.touches
        if (touches.length === 1) {
            e.preventDefault()
            e.stopPropagation()
            isMoving.current = true
            collectInertia(touches)
            let diff = touches[0].pageX - startPoint.current.x
            offset.current.x += diff
            startPoint.current = {
                x: touches[0].pageX,
                y: touches[0].pageY
            }
            if (wrapRef.current) {
                let canScrollOffset = -wrapRef.current.scrollWidth + wrapRef.current.clientWidth
                const parent = wrapRef.current.parentNode
                const maxOffset = wrapRef.current.scrollWidth - parent.offsetWidth
                offset.current.x = Math.min(offset.current.x, 0)
                offset.current.x = Math.max(offset.current.x, -maxOffset)
                // offset.current.x = Math.min(offset.current.x, 0)
                // offset.current.x = Math.max(offset.current.x, canScrollOffset)
                wrapRef.current.style.setProperty('transition', 'none')
                wrapRef.current.style.setProperty('transform', `translate3d(${offset.current.x}px, 0, 0)`)
            }
        }
    }, [collectInertia])


    const realizeInertia = useCallback(() => {
        if (velocity.current) {
            let {x, y} = velocity.current
            _inAnimation.current = false
            resetInertia()
            const renderFrame = () => {
                x *= 0.95
                y *= 0.95
                let canScrollOffset = -wrapRef.current.scrollWidth + wrapRef.current.clientWidth
                offset.current.x += x
                offset.current.y += y
                const parent = wrapRef.current.parentNode
                const maxOffset = wrapRef.current.scrollWidth - parent.offsetWidth
                offset.current.x = Math.min(offset.current.x, 0)
                offset.current.x = Math.max(offset.current.x, -maxOffset)
                // offset.current.x = Math.min(offset.current.x, 0)
                // offset.current.x = Math.max(offset.current.x, canScrollOffset)
                wrapRef.current.style.setProperty('transform', `translate3d(${offset.current.x}px, 0, 0)`)
            }
            animate(renderFrame, {duration: 9999})
        }
    }, [resetInertia, animate])

    const onTouchEnd = useCallback((e) => {
        if (isMoving.current) {
            realizeInertia()
            isMoving.current = false
        }
    }, [realizeInertia])

    const onResize = useThrottleFn(() => {
        const {value} = getTransformByIndex(activeIndex)
        wrapRef.current.style.setProperty('transition', 'none')
        wrapRef.current.style.setProperty('transform', value)
    }, 100, [getTransformByIndex])

    useEffect(() => {
        const node = wrapRef.current
        window.addEventListener('resize', onResize)
        node.addEventListener('touchmove', onTouchMove)
        return function remove() {
            window.removeEventListener('resize', onResize)
            node.removeEventListener('touchmove', onTouchMove)
        }
    }, [onTouchMove])

    return <div
        ref={wrapRef}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        style={{
            // background: tabBarBackgroundColor || '#fff',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 0,
            minHeight: 43.5,
            alignItems: 'center'
        }}>
        {renderTabs}
        {/*{useUnderline ? <div ref={lineRef} style={underLineStyle}></div> : null}*/}
        {/*<div ref={lineRef} style={underLineStyle}></div>*/}
    </div>
}
