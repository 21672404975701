import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="1">
            <g id="SVG-logo" fillRule="nonzero">
                <g id="Group">
                    <path
                        d="M1.90973307,2.08935102 C0.629905324,3.34466664 -0.00665488259,5.18509251 5.2452922e-05,7.61062862 L5.24529219e-05,34.3760934 C0.197698118,36.1489745 0.834258325,37.6401292 1.90973307,38.8495576 C2.98520782,40.058986 4.66388707,40.772147 6.94577083,40.9890406 L33.0115871,40.9890406 C35.5372105,41.0986918 37.4967174,40.3855309 38.8901078,38.8495576 C40.2834982,37.3135844 40.9681286,35.8224297 40.9439989,34.3760934 L40.9439989,7.61062862 C41.1879698,5.22533205 40.6347891,3.38490618 39.2844569,2.08935102 C37.9341247,0.793795868 35.8431681,0.0973455266 33.0115871,0 L6.94577083,0 C4.86824007,0.137585063 3.18956082,0.834035404 1.90973307,2.08935102 Z"
                        id="bg" fill="black"></path>
                    <path
                        d="M18.1346154,27.1673905 C18.1346154,27.7717921 18.1346154,27.9310336 17.6012293,28.1948833 C17.0678431,28.4587331 16.553289,28.4719421 16.1691299,28.0775915 C16.0703765,27.8880493 15.5714785,26.448144 14.6724359,23.7578757 L9.02428916,23.7578808 L7.39935527,27.9310336 C6.88335657,28.27248 6.42513485,28.3604299 6.02469009,28.1948833 C5.62424533,28.0293367 5.46173664,27.6036406 5.53716402,26.9177948 C8.67654914,17.2254947 10.3427296,12.1336117 10.5357054,11.6421461 C10.7689202,11.2710996 10.9952184,11.0384615 11.7558883,11.0384615 C12.5125014,11.0384615 12.6282608,11.2138926 12.9640618,11.6421461 C13.0878438,12.0059038 18.1346154,26.5629888 18.1346154,27.1673905 Z M11.7868004,14.9374915 L9.56981145,21.5854313 L14.0197449,21.5854313 L11.7868004,14.9374915 Z"
                        id="A" fill="white"></path>
                    <path
                        d="M18.2909107,9.65319036 C18.4555932,9.47679462 18.59551,9.50182309 18.9139013,9.49856601 C23.7400128,9.44919594 28.5707217,9.44919594 33.4060279,9.49856601 C33.6080131,9.4987554 33.7414791,9.5288783 33.8064259,9.58893471 C33.8713727,9.64899112 33.9038462,9.77562009 33.9038462,9.96882163 L33.9038462,27.0988677 C33.8877415,27.2875426 33.8166402,27.4187714 33.6905423,27.4925541 C33.5644444,27.5663368 33.371968,27.5971566 33.113113,27.5850133 L18.6980598,27.5850133 C18.4879663,27.6150889 18.3340015,27.5842692 18.2361655,27.4925541 C18.1383295,27.400839 18.1146065,27.2650877 18.1649966,27.0853001 C18.1244883,21.4659372 18.1244883,15.8521815 18.1649966,10.244033 C18.1671912,9.9402008 18.1262283,9.82958611 18.2909107,9.65319036 Z M19.7915206,12.7716414 C21.3725894,17.1465156 23.1428533,21.5338518 25.1023122,25.9336498 C25.34774,26.3765354 25.7159335,26.6111839 26.2068928,26.6375951 C26.7008573,26.5700044 27.0860777,26.3766562 27.362554,26.0575507 L32.4921703,13.04946 C32.707205,12.2628723 32.4777858,11.6720066 31.8039125,11.2768629 C30.9554004,11.1305488 30.4015838,11.3153111 30.1424628,11.8311501 L26.2068928,22.1158026 L22.1631238,11.8311501 C21.813489,11.3410096 21.2549504,11.1562472 20.4875078,11.2768629 C19.9060955,11.5391014 19.658027,12.292369 19.7915206,12.7716414 Z"
                        id="V" fill="#ED9D3C"></path>
                    <path
                        d="M14.1923077,29.9615385 L15.8018958,29.9615385 C16.1422472,30.1642414 16.3124229,30.4460574 16.3124229,30.8069864 C16.3124229,31.1679155 16.1954793,31.4735246 15.961592,31.7238138 C16.3589922,32.0615586 16.5576923,32.4005558 16.5576923,32.7408055 C16.5576923,33.0810552 16.3467668,33.4687354 15.9249157,33.9038462 L14.1923077,33.9026708 L14.1923077,29.9615385 Z M14.6906895,30.3589035 L14.6906895,31.4538978 L15.6152566,31.4538978 C15.767159,31.2713988 15.8431102,31.0888997 15.8431102,30.9064007 C15.8431102,30.7239016 15.767159,30.5414026 15.6152566,30.3589035 L14.6906895,30.3589035 Z M14.6850279,32.0364565 L14.6850279,33.3391715 L15.8228283,33.3391715 C16.0271273,33.146448 16.1292768,32.9293289 16.1292768,32.687814 C16.1292768,32.4462992 15.9822816,32.22918 15.6882911,32.0364565 L14.6850279,32.0364565 Z"
                        id="B" fill="white"></path>
                    <path
                        d="M18.9033585,29.1730769 C19.9138524,29.1730769 20.4460662,29.9615387 20.5,31.5384623 C20.4460662,33.1153849 19.9138524,33.9038462 18.9033585,33.9038462 C17.8928646,33.9038462 17.3737964,33.1153846 17.3461538,31.5384615 C17.3737964,29.9615385 17.8928646,29.1730769 18.9033585,29.1730769 Z M18.9451674,29.6338332 C18.2485089,29.6338332 17.8593433,30.2687096 17.7776705,31.5384623 C17.8391481,32.8286725 18.2283137,33.4737777 18.9451674,33.4737777 C19.6620211,33.4737777 20.0453357,32.8286725 20.0951113,31.5384623 C20.0251405,30.2687096 19.6418259,29.6338332 18.9451674,29.6338332 Z"
                        id="O" fill="white"></path>
                    <path
                        d="M21.2884615,29.9615385 L22.8980497,29.9615385 C23.2384011,30.1642414 23.4085768,30.4460574 23.4085768,30.8069864 C23.4085768,31.1679155 23.2916331,31.4735246 23.0577458,31.7238138 C23.4551461,32.0615586 23.6538462,32.4005558 23.6538462,32.7408055 C23.6538462,33.0810552 23.4429206,33.4687354 23.0210696,33.9038462 L21.2884615,33.9026708 L21.2884615,29.9615385 Z M21.7868433,30.3589035 L21.7868433,31.4538978 L22.7114105,31.4538978 C22.8633129,31.2713988 22.9392641,31.0888997 22.9392641,30.9064007 C22.9392641,30.7239016 22.8633129,30.5414026 22.7114105,30.3589035 L21.7868433,30.3589035 Z M21.7811817,32.0364565 L21.7811817,33.3391715 L22.9189822,33.3391715 C23.1232812,33.146448 23.2254307,32.9293289 23.2254307,32.687814 C23.2254307,32.4462992 23.0784354,32.22918 22.784445,32.0364565 L21.7811817,32.0364565 Z"
                        id="B" fill="white"></path>
                    <path
                        d="M25.9995124,29.1730769 C27.0100063,29.1730769 27.5422201,29.9615387 27.5961538,31.5384623 C27.5422201,33.1153849 27.0100063,33.9038462 25.9995124,33.9038462 C24.9890185,33.9038462 24.4699502,33.1153846 24.4423077,31.5384615 C24.4699502,29.9615385 24.9890185,29.1730769 25.9995124,29.1730769 Z M26.0413212,29.6338332 C25.3446627,29.6338332 24.9554971,30.2687096 24.8738243,31.5384623 C24.9353019,32.8286725 25.3244675,33.4737777 26.0413212,33.4737777 C26.7581749,33.4737777 27.1414895,32.8286725 27.1912651,31.5384623 C27.1212943,30.2687096 26.7379797,29.6338332 26.0413212,29.6338332 Z"
                        id="O" fill="white"></path>
                </g>
            </g>
        </g>
    </svg>
}



