import styled from "@xstyled/styled-components";

export const TabBar = styled.ul`
  list-style: none;
  display: flex;
  flex: 1;
  padding: 0px;
  margin: 0;
  background-color: #fff;
`

export const Tab = styled.li`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.active ? 'red' : '#000'};
  position: relative;
  :after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: ${(p) => p.active ? 'red' : ''};
  }
`

export const ListWrap = styled.div`
  background-color: #ccc;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666
`
