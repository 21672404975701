import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox,firstColor,secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="注册1111" transform="translate(-83.000000, -464.000000)" fillRule="nonzero">
                <g id="gifts" transform="translate(83.000000, 464.000000)">
                    <g id="Group">
                        <path
                            d="M14,18 L14,28 L4,28 C2.8954305,28 2,27.1045695 2,26 L2,18 L14,18 Z M14,8 L2,8 C0.8954305,8 1.3527075e-16,8.8954305 0,10 L0,15 C6.76353751e-17,15.5522847 0.44771525,16 1,16 L14,16 L14,8 Z M30,8 L18,8 L18,16 L31,16 C31.5522847,16 32,15.5522847 32,15 L32,10 C32,8.8954305 31.1045695,8 30,8 Z M18,28 L28,28 C29.1045695,28 30,27.1045695 30,26 L30,18 L18,18 L18,28 Z"
                            id="Shape" fill={firstColor || "#DBDFE2"}></path>
                        <path
                            d="M14,28 L18,28 L18,18 L14,18 L14,28 Z M22.47125,0 C19.8575,0 18.1675,1.33125 16,4.26875 C13.8325,1.33125 12.1425,0 9.52875,0 C6.481875,0 4,2.46875 4,5.5 C4.00453985,6.37258173 4.2223192,7.23082545 4.634375,8 L9.5225,8 C8.14178813,8 7.0225,6.88071187 7.0225,5.5 C7.0225,4.11928813 8.14178813,3 9.5225,3 C10.7725,3 11.69625,3.20625 14.931875,8 L14,8 L14,16 L18,16 L18,8 L17.0625,8 C20.291875,3.21875 21.1875,3 22.471875,3 C23.8525869,3 24.971875,4.11928813 24.971875,5.5 C24.971875,6.88071187 23.8525869,8 22.471875,8 L27.36625,8 C27.7752559,7.22967424 27.99264,6.37214299 28,5.5 C28,2.46875 25.518125,0 22.47125,0 Z"
                            id="Shape" fill={secondColor || "#ADB5BD"}></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}



