import {Banner, ItemWrapper} from "../../views/home/style";
import React, {useCallback} from "react";
import {useNavigate} from 'react-router-dom'
import Image from "../../ui/Image";

export default function (props) {
    const {
        id,
        title,
        src
    } = props

    const navigate = useNavigate()

    const goDetail = useCallback(() => {
        navigate('/detail/' + id)
    }, [id])

    return <ItemWrapper onClick={goDetail}>
        <Image src={src} />
        {/*<Banner src={src}/>*/}
    </ItemWrapper>
}