import React, {useState, useCallback, useRef, Fragment, useEffect} from 'react'
import Vip from './vip'
import Dot from './dot'
import {
    Section,
    Main,
    Nav,
    NavWrap,
    NavBottom,
    Panel,
    TabItem,
    NavBottomText,
    ContentContainer
} from './style'

export function Item(props) {
    return <Fragment>
        {props.children}
    </Fragment>
}

function TabPane(props) {
    let hidden = props.active ? false : true
    let newChildren = React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
            onPress: () => {
            }, ...child.props
        })
        // console.log(child, index)
    })
    return <Panel style={{height: hidden ? 0 : null}} hidden={hidden}>
        {newChildren}
    </Panel>
}

function Tab(props) {
    const {
        key,
        icon,
        selected,
        selectedIcon,
        tintColor,
        onClick,
        onPress,
        index,
        dot,
        vip,
        unselectedTintColor
    } = props

    const renderIcon = () => {
        return selected ? selectedIcon : icon
    }

    let color = selected ? tintColor : unselectedTintColor

    return <TabItem>
        <div
            onClick={() => {
                if (!selected) {
                    onPress && onPress(index, key)
                    onClick && onClick()
                }
            }}
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
            {dot && <Dot dot/>}
            {vip && <Vip dot/>}
            {renderIcon()}
            <NavBottomText color={color} selected={selected}>{props.title}</NavBottomText>
        </div>
    </TabItem>
}

export default function (props) {
    const {initialIndex, hidden} = props
    const [currentTab, setTab] = useState(initialIndex)
    const tabCache = useRef({})


    useEffect(() => {
        setTab(initialIndex)
    }, [initialIndex])

    const onPress = useCallback((index) => {
        setTab(index)
    }, [])

    const renderTabBar = () => {
        let Tabs = React.Children.map(props.children, (c, index) => {
            let selected = currentTab === index
            let _props = {
                ...c.props,
                ...props,
                selected,
                index,
                onClick: () => onPress(index)
            }
            return <Tab {..._props} />
        })
        return <Nav style={{order: 2}}>
            <NavWrap hidden={hidden}>
                <NavBottom barTintColor={props.barTintColor} hidden={hidden}>
                    {Tabs}
                </NavBottom>
            </NavWrap>
        </Nav>
    }

    const renderContent = () => {
        let subElemens = {}
        React.Children.forEach(props.children, (child, index) => {
            if (child.key) {
                subElemens[child.key] = child
            }
        })
        return React.Children.map(props.children, (c, index) => {
            if (currentTab - 0 <= index && index <= currentTab + 0 || c.props.prerendering) {
                tabCache.current[index] = subElemens[c.key]
            }
            return <TabPane active={currentTab === index}>
                {tabCache.current[index]}
            </TabPane>
        })
    }

    return <Section>
        {renderContent()}
        {renderTabBar()}
    </Section>
}
