import NavBar from "../../ui/NavBar";
import SafeAreaView from "../../ui/SafeAreaView";
import React, {useCallback} from "react";
import Icon from "../../svgIcon";
import {Main, SearchBar, History} from "./style";

export default function () {

    const onSubmit = useCallback((e) => {
        e.preventDefault()
    }, [])

    return <SafeAreaView>
        <NavBar/>
        <Main>
            <SearchBar onSubmit={onSubmit} action="#">
                <Icon name={'search'}/>
                <input placeholder="请输入作品名称" />
            </SearchBar>
            {/*<History>最近在搜</History>*/}
        </Main>
    </SafeAreaView>
}