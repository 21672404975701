import App from "../App";
import Detail from "../component/views/detail";
import Order from "../component/views/order";
import Book from "../component/views/book";
import Search from "../component/views/search";
import Login from "../component/views/login";
import Card from "../component/views/card";
import Event from '../component/views/event'
import React from "react";

export default [
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/:name",
        element: <App />,
    },
    // {
    //     path: "/index",
    //     element: <App />,
    // },
    // {
    //     path: "/product",
    //     element: <App />,
    // },
    // {
    //     path: "/user",
    //     element: <App />,
    // },
    {
        path: '/detail/:id',
        element: <Detail />
    },
    {
        path: '/order',
        element: <Order />
    },
    {
        path: '/book',
        element: <Book/>
    },
    {
        path: '/search',
        element: <Search/>
    },
    {
        path: '/login',
        element: <Login/>
    },
    {
        path: '/card',
        element: <Card/>
    },
    {
        path: '/event',
        element: <Event/>
    }
]