import styled from '@xstyled/styled-components';

export const Wrapper = styled.div`
    position: relative;
    z-index: 9999;
`

export const Container = styled.div`
    height: env(safe-area-inset-top);
`

export const Content = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: #000;
    box-sizing: border-box;
    height: 20px;
    height: env(safe-area-inset-top);
    padding-top: env(safe-area-inset-top)
`

export const FullScreen = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    margin-top: 0px;
    z-index: 1;
`
