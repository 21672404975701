import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="首页" transform="translate(-192.000000, -14.000000)" fillRule="nonzero">
                <g id="SVG-Layer" transform="translate(192.000000, 14.000000)">
                    <g id="Group">
                        <path
                            d="M13,0 C5.81854839,0 0,5.81854839 0,13 C0,20.1814516 5.81854839,26 13,26 C20.1814516,26 26,20.1814516 26,13 C26,5.81854839 20.1814516,0 13,0 Z M19.4737903,14.2737903 L13.6762097,20.2600806 C13.5047995,20.4401308 13.2688809,20.544708 13.0203606,20.550803 C12.7718402,20.556898 12.5310787,20.4640116 12.3510484,20.2925806 C12.3400403,20.2820968 12.3290323,20.2710887 12.3185484,20.2600806 L6.52620968,14.2737903 C4.84354839,12.5334677 4.94314516,9.65040323 6.825,8.04637097 C8.46572581,6.64677419 10.9084677,6.8983871 12.4129032,8.45 L13.0052419,9.05806452 L13.5975806,8.45 C15.1020161,6.8983871 17.5447581,6.64677419 19.1854839,8.04637097 C21.0620968,9.65040323 21.1616935,12.5334677 19.4737903,14.2737903 Z"
                            id="Shape" fill={firstColor || "#DBDFE2"}></path>
                        <path
                            d="M19.4737903,14.2737903 L13.6762097,20.2600806 C13.5047995,20.4401308 13.2688809,20.544708 13.0203606,20.550803 C12.7718402,20.556898 12.5310787,20.4640116 12.3510484,20.2925806 C12.3400403,20.2820968 12.3290323,20.2710887 12.3185484,20.2600806 L6.52620968,14.2737903 C4.84354839,12.5334677 4.94314516,9.65040323 6.825,8.04637097 C8.46572581,6.64677419 10.9084677,6.8983871 12.4129032,8.45 L13.0052419,9.05806452 L13.5975806,8.45 C15.1020161,6.8983871 17.5447581,6.64677419 19.1854839,8.04637097 C21.0620968,9.65040323 21.1616935,12.5334677 19.4737903,14.2737903 Z"
                            id="Path" fill={secondColor || "#ADB5BD"}></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}











