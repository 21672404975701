import React, {useState, useCallback, Fragment} from "react";
import {Wrap,Main, Title, BackBtn, Fixed} from './style'
import {useNavigate} from "react-router-dom";
import Icon from "../../svgIcon";

export default function (props) {
    const navigate = useNavigate()
    const {
        title = '',
        showBack = true,
        fixed = false
    } = props

    const onBack = useCallback(() => {
        navigate(-1)
    }, [])

    const renderBackBtn = useCallback(() => {
        if(showBack) {
            return <BackBtn onClick={onBack}>
                <Icon name={'back'}/>
            </BackBtn>
        }
    }, [showBack, onBack])

    const renderBar = useCallback(() => {
        if(fixed) {
            return <Fixed>
                {renderBackBtn()}
                <Title>{title}</Title>
                <div></div>
            </Fixed>
        } else {
            return <Main>
                {renderBackBtn()}
                <Title>{title}</Title>
                <div></div>
            </Main>
        }
    }, [fixed,title, renderBackBtn])

    return <Wrap>
        {renderBar()}
    </Wrap>
}