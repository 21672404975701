import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="首页" transform="translate(-333.000000, -14.000000)" fillRule="nonzero">
                <g id="SVG-Layer" transform="translate(333.000000, 14.000000)">
                    <g id="Group" transform="translate(0.000000, 0.485597)">
                        <path
                            d="M13,0 C5.81854839,0 0,5.67308468 0,12.675 C0,19.6769153 5.81854839,25.35 13,25.35 C20.1814516,25.35 26,19.6769153 26,12.675 C26,5.67308468 20.1814516,0 13,0 Z M13,4.90645161 C15.5476361,4.90645161 17.6129032,6.92008706 17.6129032,9.40403226 C17.6129032,11.8879775 15.5476361,13.9016129 13,13.9016129 C10.4523639,13.9016129 8.38709677,11.8879775 8.38709677,9.40403226 C8.38709677,6.92008706 10.4523639,4.90645161 13,4.90645161 Z M13,22.4879032 C10.0383044,22.4867659 7.22809981,21.2112399 5.32056452,19.0022782 C6.30604839,17.1930242 8.23508065,15.9459677 10.483871,15.9459677 C10.6100737,15.9469653 10.7354525,15.9659045 10.8560484,16.0021875 C12.2446713,16.4723816 13.7553287,16.4723816 15.1439516,16.0021875 C15.2645475,15.9659045 15.3899263,15.9469653 15.516129,15.9459677 C17.7649194,15.9459677 19.6939516,17.1930242 20.6794355,19.0022782 C18.7719002,21.2112399 15.9616956,22.4867659 13,22.4879032 Z"
                            id="Shape" fill={firstColor || "#DBDFE2"}></path>
                        <path
                            d="M13,13.9016129 C15.5476361,13.9016129 17.6129032,11.8879775 17.6129032,9.40403226 C17.6129032,6.92008706 15.5476361,4.90645161 13,4.90645161 C10.4523639,4.90645161 8.38709677,6.92008706 8.38709677,9.40403226 C8.38709677,11.8879775 10.4523639,13.9016129 13,13.9016129 Z M15.516129,15.9459677 C15.3899263,15.9469653 15.2645475,15.9659045 15.1439516,16.0021875 C13.7553287,16.4723816 12.2446713,16.4723816 10.8560484,16.0021875 C10.7354525,15.9659045 10.6100737,15.9469653 10.483871,15.9459677 C8.23508065,15.9459677 6.30604839,17.1930242 5.32056452,19.0022782 C8.88875,23.137497 15.2194355,23.6695403 19.4606855,20.1905595 C19.9010377,19.8291728 20.3087825,19.4316217 20.6794355,19.0022782 C19.6939516,17.1930242 17.7649194,15.9459677 15.516129,15.9459677 Z"
                            id="Shape" fill={secondColor || "#ADB5BD"}></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}









