import {ArtBox, ImgMask, ImgWrap, Title} from "../../views/home/style";
import React, {useCallback} from "react";
import {useNavigate} from 'react-router-dom'
import Image from '../../ui/Image'

export default function (props) {
    const {
        title,
        src,
        id,
    } = props

    const navigate = useNavigate()

    const goDetail = useCallback(() => {
        navigate('/detail/' + id)
    }, [id])


    return <ArtBox onClick={goDetail}>
        <ImgWrap>
            <Image src={src} />
        </ImgWrap>
        {title ? <ImgMask>
            <Title>{title}</Title>
        </ImgMask> : null}

    </ArtBox>
}