import React, { useRef, useMemo } from 'react'
// import { Observer } from 'mobx-react-lite'
import { FullScreen, Wrapper, Container, Content } from './style'
// import { isAddhomeScreen } from '../../../utils/homeScreenUtil'
// import isSmall from "../../../utils/isSmallApp";
// import { getStore } from "../../../store";
// import theme from '../../../themeConfig'

export default function ({ children, className, style, parentStyle, statusBar }) {
    const statusBarRef = useRef(null)
    const renderContent = () => {
        if (children instanceof Function) {
            let height = statusBarRef.current ? statusBarRef.current.offsetHeight : 0
            return children(height)
        } else {
            return children
        }
    }

    const renderStatusBar = useMemo(() => {
        if (statusBar) {
            return <Wrapper>
                <Container>
                    <Content style={{ background: 'transparent' }} id={'statusBar'} ref={statusBarRef} />
                </Container>
            </Wrapper>
        } else {
            return null
        }
    }, [statusBar])

    return <FullScreen className={className} style={style}>
        {renderStatusBar}
        <div style={parentStyle || {
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
            background: 'transparent',
            position: 'relative'
        }}>
            {renderContent()}
        </div>
    </FullScreen>
}
