import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <filter x="-8.9%" y="-8.9%" width="117.9%" height="117.9%" filterUnits="objectBoundingBox" id="shortVideoHighlight-filter-1">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0.854901961   0 0 0 0 0.294117647   0 0 0 0 0.968627451  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="shortVideoHighlight-linearGradient-2">
                <stop stopColor="#24DFFF" stopOpacity="0.0317690122" offset="0%"/>
                <stop stopColor="#24DFFF" offset="100%"/>
            </linearGradient>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="shortVideoHighlight-linearGradient-3">
                <stop stopColor="#EF82FC" stopOpacity="0.579190341" offset="0%"/>
                <stop stopColor="#FF328B" offset="100%"/>
            </linearGradient>
            <rect id="shortVideoHighlight-path-4" x="8" y="2" width="18" height="24" rx="2"/>
        </defs>
        <g id="icon/tabbar/video-highlight" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g filter="url(#shortVideoHighlight-filter-1)">
                <rect id="矩形" fill="#110036" opacity="0" x="0" y="0" width="28" height="28"/>
                <rect id="矩形" fill="url(#shortVideoHighlight-linearGradient-2)" x="2" y="3" width="12" height="22" rx="2"/>
                <g id="矩形备份-9">
                    <use fill="url(#shortVideoHighlight-linearGradient-3)" fillRule="evenodd" xlinkHref="#shortVideoHighlight-path-4"/>
                    <rect stroke="#DA4BF7" strokeWidth="0.1" strokeLinejoin="square" x="8.05" y="2.05" width="17.9" height="23.9" rx="2"/>
                </g>
            </g>
        </g>
    </svg>
}














