import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <linearGradient x1="99.8652645%" y1="50%" x2="4.68098109%" y2="50%" id="rank-linearGradient-1">
                <stop stopColor="#696D91" offset="0%"/>
                <stop stopColor="#696D91" offset="100%"/>
            </linearGradient>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="rank-linearGradient-2">
                <stop stopColor="#ADB4EC" offset="0%"/>
                <stop stopColor="#ADB4EC" offset="100%"/>
            </linearGradient>
            <path
                d="M17,3 C17.5522847,3 18,3.44771525 18,4 L18,13 L25,13 C25.5522847,13 26,13.4477153 26,14 L26,24 C26,24.5522847 25.5522847,25 25,25 L11,25 C10.4477153,25 10,24.5522847 10,24 L10,4 C10,3.44771525 10.4477153,3 11,3 L17,3 Z"
                id="rank-path-3"/>
        </defs>
        <g id="icon/tabbar/rank-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
           opacity="0.400000006">
            <g>
                <rect id="矩形" fill="#110036" opacity="0" x="0" y="0" width="28" height="28"/>
                <path
                    d="M10,7 L10,4 C10,3.44771525 10.4477153,3 11,3 L11,3 L17,3 C17.5522847,3 18,3.44771525 18,4 L18,4 L18,24 C18,24.5522847 17.5522847,25 17,25 L3,25 C2.44771525,25 2,24.5522847 2,24 L2,8 C2,7.44771525 2.44771525,7 3,7 L10,7 Z"
                    id="形状结合" fill="url(#rank-linearGradient-1)"/>
                <g id="形状结合">
                    <use fill="url(#rank-linearGradient-2)" fillRule="evenodd"
                         xlinkHref="#rank-path-3"/>
                    <path stroke="#ADB4EC" strokeWidth="0.1"
                          d="M17,3.05 L11,3.05 C10.7376647,3.05 10.5001647,3.15633237 10.3282486,3.32824856 C10.1563324,3.50016474 10.05,3.73766474 10.05,4 L10.05,24 C10.05,24.2623353 10.1563324,24.4998353 10.3282486,24.6717514 C10.5001647,24.8436676 10.7376647,24.95 11,24.95 L25,24.95 C25.2623353,24.95 25.4998353,24.8436676 25.6717514,24.6717514 C25.8436676,24.4998353 25.95,24.2623353 25.95,24 L25.95,14 C25.95,13.7376647 25.8436676,13.5001647 25.6717514,13.3282486 C25.4998353,13.1563324 25.2623353,13.05 25,13.05 L17.95,13.05 L17.95,4 C17.95,3.73766474 17.8436676,3.50016474 17.6717514,3.32824856 C17.4998353,3.15633237 17.2623353,3.05 17,3.05 Z"
                          strokeLinejoin="square"/>
                </g>
            </g>
        </g>
    </svg>
}







