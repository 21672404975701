import styled from '@xstyled/styled-components';

export const Page = styled.div`
  flex: 1 1 0px;
  min-height: 0px;
`

export const Main = styled.div`
  padding-bottom: 50px;
`

export const ItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
`

export const Banner = styled.div`
  width: 100%;
  height: 260px;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const DotWrap = styled.ul`
  position: absolute;
  bottom: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  background: linear-gradient(360deg,rgba(0,0,0,.399257) 2.29%,rgba(0,0,0,.0001) 99.71%);
  height: 30px;
  margin: 0;
  width: 100%;
  align-items: center;
`

export const Dot = styled.li`
  width: 8px;
  height: 8px;
  margin-left: 10px;
  border-radius: 100%;
  background-color: ${(p) => p.active ? '#fff' : 'rgba(255,255,255,.6)'};
  :first-child {
    margin-left: 0px;
  }
`

export const ArtTitle = styled.div`
  text-align: center;
  margin: 15px 0;
`

export const ArtWrap = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  li {
    flex-grow: 1;
    flex-shrink: 0;
    width: 50%;
    padding: 5px;
    box-sizing: border-box;
  }
`

export const ArtBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const ImgWrap = styled.div`
  width: 100%;
  height: 260px;
  //border-radius: 12px;
  background-color: rgba(0,0,0,0.03);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.08);
  // background-image: url(${(p) => p.src});
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
`

export const ImgMask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg,rgba(0,0,0,.28) 0,rgba(0,0,0,.28) 100%);
`

export const Title = styled.span`
  color: #fff;
  text-align: start;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: block;
  font-size: 14px;
  margin-top: 5px;
`