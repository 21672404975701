import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="公告" transform="translate(-56.000000, -202.000000)" fill-rule="nonzero">
                <g transform="translate(56.000000, 202.000000)" id="Group">
                    <g>
                        <path d="M3.7109375,6.28571429 L5.6640625,6.28571429 C5.98766685,6.28571429 6.25,6.02188208 6.25,5.69642857 L6.25,3.73214286 C6.25,3.40668934 5.98766685,3.14285714 5.6640625,3.14285714 L3.7109375,3.14285714 C3.38733315,3.14285714 3.125,3.40668934 3.125,3.73214286 L3.125,5.69642857 C3.125,6.02188208 3.38733315,6.28571429 3.7109375,6.28571429 Z M0,9.42857143 L0,19.6428571 C1.5852041e-16,20.9446712 1.04933262,22 2.34375,22 L22.65625,22 C23.9506674,22 25,20.9446712 25,19.6428571 L25,9.42857143 L0,9.42857143 Z" id="Shape" fill="#DBDFE2"></path>
                        <path d="M22.65625,-6.75650012e-15 L2.34375,-6.75650012e-15 C1.04933262,-7.10542736e-15 1.5852041e-16,1.0553288 0,2.35714286 L0,9.42857143 L25,9.42857143 L25,2.35714286 C25,1.0553288 23.9506674,-7.10542736e-15 22.65625,-6.75650012e-15 Z M6.25,5.69642857 C6.25,6.02188208 5.98766685,6.28571429 5.6640625,6.28571429 L3.7109375,6.28571429 C3.38733315,6.28571429 3.125,6.02188208 3.125,5.69642857 L3.125,3.73214286 C3.125,3.40668934 3.38733315,3.14285714 3.7109375,3.14285714 L5.6640625,3.14285714 C5.98766685,3.14285714 6.25,3.40668934 6.25,3.73214286 L6.25,5.69642857 Z M21.875,5.69642857 C21.875,6.02188208 21.6126668,6.28571429 21.2890625,6.28571429 L9.1796875,6.28571429 C8.85608315,6.28571429 8.59375,6.02188208 8.59375,5.69642857 L8.59375,3.73214286 C8.59375,3.40668934 8.85608315,3.14285714 9.1796875,3.14285714 L21.2890625,3.14285714 C21.6126668,3.14285714 21.875,3.40668934 21.875,3.73214286 L21.875,5.69642857 Z" id="Shape" fill="#ADB5BD"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}






