import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={128}
        style={style}
        height={128}
        viewBox={'0 0 128 128'}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g transform="translate(0, -300)">
            <linearGradient id="hot-linear-gradient" gradientUnits="userSpaceOnUse" x1="117.667" x2="55.491" y1="82.32" y2="82.32">
                <stop offset="0" stopColor="#ff3345"/>
                <stop offset="1" stopColor="#ff9c7a"/>
            </linearGradient>
            <linearGradient id="hot-linear-gradient-2" gradientUnits="userSpaceOnUse" x1="21.146" x2="88.032" y1="116.021"
                            y2="116.021">
                <stop offset="0" stopColor="#57ad9d"/>
                <stop offset=".181" stopColor="#53a39b"/>
                <stop offset=".49" stopColor="#4a8794"/>
                <stop offset=".889" stopColor="#3a5b89"/>
                <stop offset=".989" stopColor="#364f86"/>
            </linearGradient>
            <linearGradient id="hot-linear-gradient-3" x1="9.556" x2="115.922" xlinkHref="#hot-linear-gradient-2" y1="48.619"
                            y2="48.619"/>
            <linearGradient id="hot-linear-gradient-4" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="60.915" y2="104.039">
                <stop offset="0" stopColor="#ffe781"/>
                <stop offset=".604" stopColor="#ffbb6f"/>
                <stop offset="1" stopColor="#ffa265"/>
            </linearGradient>
            <linearGradient id="hot-linear-gradient-5" gradientUnits="userSpaceOnUse" x1="64.054" x2="64.054" y1="34.076"
                            y2="45.29">
                <stop offset="0" stopColor="#f1ddb5"/>
                <stop offset="1" stopColor="#ffcd9f"/>
            </linearGradient>
            <linearGradient id="hot-linear-gradient-6" gradientTransform="matrix(.707 -.707 .707 .707 -40.134 69.644)"
                            gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="94.375" y2="67.595">
                <stop offset="0" stopColor="#473051"/>
                <stop offset="1" stopColor="#ff4f54"/>
            </linearGradient>
            <radialGradient id="radial-gradient" cx="75" cy="7" gradientUnits="userSpaceOnUse" r="37.547">
                <stop offset="0" stopColor="#ff4f54"/>
                <stop offset="1" stopColor="#473051"/>
            </radialGradient>
            <linearGradient id="hot-linear-gradient-7" x1="67.678" x2="67.678" xlinkHref="#radial-gradient" y1="-.167"
                            y2="37.965"/>
            <linearGradient id="hot-linear-gradient-8" x1="59.309" x2="59.309" xlinkHref="#radial-gradient" y1="-.167"
                            y2="37.965"/>
            <linearGradient id="hot-linear-gradient-10" x1="76.301" x2="76.301" xlinkHref="#radial-gradient" y1="-.167"
                            y2="37.965"/>
            <linearGradient id="hot-linear-gradient-11" x1="67.894" x2="67.894" xlinkHref="#radial-gradient" y1="-.167"
                            y2="37.965"/>
            <path d="m32.154 48.072h63.691v68.496h-63.691z" fill="url(#hot-linear-gradient)"/>
            <path
                d="m97.393 107.454a1.442 1.442 0 0 0 -1.892-1.338c-11.1 2.933-22.4 3.482-31.507 3.482s-20.4-.549-31.495-3.482a1.442 1.442 0 0 0 -1.892 1.338v11.859a2.97 2.97 0 0 0 1.521 2.62c4.384 2.387 20.272 4.049 31.872 4.049s27.488-1.662 31.872-4.049a2.97 2.97 0 0 0 1.521-2.62z"
                fill="url(#hot-linear-gradient-2)"/>
            <path
                d="m96.2 38.674h-.012l-64.241-.012a1.376 1.376 0 0 0 -1.334 1.381v11.857a2.992 2.992 0 0 0 1.517 2.624c4.387 2.386 20.27 4.052 31.87 4.052s27.483-1.666 31.875-4.05a2.992 2.992 0 0 0 1.517-2.626v-11.857a1.371 1.371 0 0 0 -1.192-1.369z"
                fill="url(#hot-linear-gradient-3)"/>
            <path
                d="m64 97.874a4.985 4.985 0 0 1 -3.548-1.47l-9.588-9.588a5.023 5.023 0 0 1 0-7.095l9.588-9.589a5.019 5.019 0 0 1 7.1 0l9.588 9.589a5.023 5.023 0 0 1 0 7.095l-9.592 9.584a4.985 4.985 0 0 1 -3.548 1.474z"
                fill="url(#hot-linear-gradient-4)"/>
            <path
                d="m96.707 39.093c0-1.313-14.619-2.377-32.653-2.377s-32.654 1.064-32.654 2.377 14.614 2.982 32.648 2.982 32.659-1.669 32.659-2.982z"
                fill="url(#hot-linear-gradient-5)"/>
            <rect fill="url(#hot-linear-gradient-6)" height="13.341" rx=".957"
                  transform="matrix(.707 .707 -.707 .707 77.625 -20.866)" width="13.341" x="57.33" y="76.598"/>
            <path
                d="m63.069 41.419a2 2 0 0 1 -1.645-3.136c.992-1.436.461-4.274-.1-7.279-1.08-5.776-2.559-13.688 5.757-19.064a2 2 0 0 1 2.171 3.36c-6.063 3.919-5.059 9.286-4 14.969.719 3.845 1.4 7.478-.538 10.286a2 2 0 0 1 -1.645.864z"
                fill="url(#radial-gradient)"/>
            <path
                d="m67.708 8.2a1.5 1.5 0 0 1 -1.5-1.477l-.062-4.145a1.5 1.5 0 0 1 1.479-1.518 1.529 1.529 0 0 1 1.522 1.477l.062 4.145a1.5 1.5 0 0 1 -1.478 1.518z"
                fill="url(#hot-linear-gradient-7)"/>
            <path
                d="m57.236 14.785a1.5 1.5 0 0 1 -.022-3l4.144-.061a1.483 1.483 0 0 1 1.523 1.476 1.5 1.5 0 0 1 -1.481 1.524l-4.144.061z"
                fill="url(#hot-linear-gradient-8)"/>
            <path
                d="m57.236 14.785a1.5 1.5 0 0 1 -.022-3l4.144-.061a1.483 1.483 0 0 1 1.523 1.476 1.5 1.5 0 0 1 -1.481 1.524l-4.144.061z"
                fill="url(#hot-linear-gradient-8)"/>
            <path d="m74.228 14.561a1.5 1.5 0 0 1 -.015-3l4.144-.043a1.5 1.5 0 1 1 .032 3l-4.145.043z"
                  fill="url(#hot-linear-gradient-10)"/>
            <g fill="url(#hot-linear-gradient-11)">
                <path
                    d="m67.915 25.2a1.5 1.5 0 0 1 -1.5-1.484l-.043-4.145a1.5 1.5 0 0 1 1.484-1.515h.017a1.5 1.5 0 0 1 1.5 1.484l.043 4.145a1.5 1.5 0 0 1 -1.485 1.515z"/>
                <path
                    d="m67.915 25.2a1.5 1.5 0 0 1 -1.5-1.484l-.043-4.145a1.5 1.5 0 0 1 1.484-1.515h.017a1.5 1.5 0 0 1 1.5 1.484l.043 4.145a1.5 1.5 0 0 1 -1.485 1.515z"/>
            </g>
        </g>
    </svg>
}













