import styled from "@xstyled/styled-components";

export const TabBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
`

export const TabBar = styled.div`
  flex: 1;
  overflow: hidden;
`

export const SearchBtn = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10px
`