import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="首页" transform="translate(-89.000000, -606.000000)" fillRule="nonzero">
                <g id="SVG-Layer" transform="translate(89.000000, 606.000000)">
                    <g id="Group">
                        <path
                            d="M18.7780357,3 C17.6117621,4.09482685 16.5215911,5.26948356 15.5152679,6.515625 C13.9332143,4.31367188 11.9716071,2.08183594 9.75,0 C4.04741071,5.34199219 0,12.3046875 0,16.5 C0,23.9560547 5.82040179,30 13,30 C20.1795982,30 26,23.9560547 26,16.5 C26,13.3787109 22.9821429,6.94101563 18.7780357,3 Z M18.7130357,24.4962891 C17.0872763,25.6441771 15.1498466,26.256699 13.1659821,26.25 C10.6164732,26.25 8.26602679,25.3775391 6.54642857,23.79375 C5.65165302,22.9708113 4.94165112,21.9640943 4.46410714,20.8412109 C3.96674107,19.6769531 3.71428571,18.3984375 3.71428571,17.0361328 C3.71428571,13.5539063 6.19821429,10.8023438 9.76741071,6.48222656 C11.5084821,8.50898438 10.8404911,7.64121094 15.6116071,13.7824219 L19.2098214,9.6375 C20.62125,11.9958984 20.8220536,12.3087891 21.2317857,13.1003906 C22.2106668,14.9821699 22.5180686,17.1468106 22.1023214,19.2304688 C21.6676339,21.3896484 20.4633929,23.2617188 18.7130357,24.4962891 Z"
                            id="Shape" fill={firstColor || "#DBDFE2"}></path>
                        <path
                            d="M17.6480804,22.9599609 C16.3335171,23.8853358 14.7685248,24.3794181 13.1659821,24.375 C8.97870536,24.375 5.57142857,21.5777344 5.57142857,17.0361328 C5.57142857,14.7738281 6.98227679,12.7804687 9.79584821,9.375 C10.1980357,9.84375 15.5315179,16.7191406 15.5315179,16.7191406 L18.9341518,12.8003906 C19.1744196,13.2017578 19.3932143,13.5943359 19.5882143,13.9722656 C21.1754911,17.0302734 20.5057589,20.9449219 17.6480804,22.9617188 L17.6480804,22.9599609 Z"
                            id="Path" fill={secondColor || "#ADB5BD"}></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}







