import React, {useCallback, useState} from 'react'
import TabsView from '../../ui/Tabs'
import DefaultTabBar from '../../ui/Tabs/DefaultTabBar'
import {TabBarContainer, SearchBtn, TabBar} from './style'
import {ArtWrap} from "../home/style";
import NavBar from "../../ui/NavBar";
import Icon from "../../svgIcon";
import Portrait from "../../item/portrait";
import tabs from "../../../data/tabs";
import {useNavigate} from 'react-router-dom'
import group from "../../../data/group";

export default function () {
    const active = 0
    const navigate = useNavigate()
    const [tabId, setTabId] = useState('chengshi')
    const [arts, setArts] = useState(group['chengshi'])

    const goSearch = useCallback(() => {
        navigate('/search')
    }, [])

    const renderTabBar = useCallback((props) => {
        return <TabBarContainer>
            <TabBar>
                <DefaultTabBar initIndex={props.activeTab} tabBarPosition={props.tabBarPosition}
                               onChange={props.onChange} tabs={props.tabs}
                               activeTab={props.activeTab}
                               animated={props.animated} />
            </TabBar>
            <SearchBtn onClick={goSearch}>
                <Icon name={'search'}/>
            </SearchBtn>
        </TabBarContainer>
    }, [])

    const renderList = useCallback(() => {
        let data = group[tabId] || []
        return data.map((item, index) => {
            return <li key={index}>
                <Portrait id={item.id} src={item.src} />
            </li>
        })
    }, [tabId])

    const renderTabContent = useCallback((item, index) => {
        return <div key={index}>
            <ArtWrap>
                {renderList()}
            </ArtWrap>
        </div>
    }, [renderList])

    return <div>
        <NavBar title={'作品'} fixed={true} showBack={false} />
        <TabsView
            tabBarBackgroundColor={'transparent'}
            tabs={tabs}
            onChange={(tab) => {
                setTabId(tab.id)
                let data = group[tab.id] || []
                setArts(data)
            }}
            notify={true}
            active={active}
            initIndex={0}
            renderTabBar={renderTabBar}>
            {tabs.map((item,index) => {
                return renderTabContent(item, index)
            })}
        </TabsView>
    </div>
}