import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={128}
        style={style}
        height={128}
        viewBox={'0 0 128 128'}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g transform="translate(0, -300)">
            <linearGradient id="game-linear-gradient" gradientUnits="userSpaceOnUse" x1="63.999" x2="63.999" y1="104.211"
                            y2="124.55">
                <stop offset="0" stopColor="#ff3345"/>
                <stop offset="1" stopColor="#ff9c7a"/>
            </linearGradient>
            <linearGradient id="game-linear-gradient-2" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="86.85" y2="113.086">
                <stop offset="0" stopColor="#57ad9d"/>
                <stop offset=".181" stopColor="#53a39b"/>
                <stop offset=".49" stopColor="#4a8794"/>
                <stop offset=".889" stopColor="#3a5b89"/>
                <stop offset=".989" stopColor="#364f86"/>
            </linearGradient>
            <linearGradient id="game-linear-gradient-3" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="118.5" y2="105.266">
                <stop offset="0" stopColor="#ffe781"/>
                <stop offset=".604" stopColor="#ffbb6f"/>
                <stop offset="1" stopColor="#ffa265"/>
            </linearGradient>
            <linearGradient id="game-linear-gradient-4" x1="123.455" x2="91.455" xlinkHref="#game-linear-gradient-3" y1="51.275"
                            y2="52.108"/>
            <linearGradient id="game-linear-gradient-5" x1="4.843" x2="36.677" xlinkHref="#game-linear-gradient-3" y1="39.922"
                            y2="51.755"/>
            <linearGradient id="game-linear-gradient-6" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="41.833" y2="74.084">
                <stop offset="0" stopColor="#473051"/>
                <stop offset="1" stopColor="#ff4f88"/>
            </linearGradient>
            <linearGradient id="game-linear-gradient-7" gradientUnits="userSpaceOnUse" x1="39.777" x2="24.027" y1="67.084"
                            y2="69.084">
                <stop offset="0" stopColor="#ffaea6"/>
                <stop offset="1" stopColor="#ffe0d3"/>
            </linearGradient>
            <linearGradient id="game-linear-gradient-8" x1="64" x2="64" xlinkHref="#game-linear-gradient" y1="38.917" y2="-2.559"/>
            <linearGradient id="game-linear-gradient-9" x1="89.695" x2="109.528" xlinkHref="#game-linear-gradient-7" y1="68.066"
                            y2="67.566"/>
            <linearGradient id="game-linear-gradient-10" x1="64" x2="64" xlinkHref="#game-linear-gradient-7" y1="103.25" y2="26.303"/>
            <linearGradient id="game-linear-gradient-11" x1="48.465" x2="47.152" xlinkHref="#game-linear-gradient-6" y1="65.146"
                            y2="49.021"/>
            <linearGradient id="game-linear-gradient-12" x1="48.617" x2="48.617" xlinkHref="#game-linear-gradient-6" y1="47.667"
                            y2="61.417"/>
            <linearGradient id="game-linear-gradient-13" gradientTransform="matrix(-1 0 0 1 128 0)" x1="48.465" x2="47.152"
                            xlinkHref="#game-linear-gradient-6" y1="65.146" y2="49.021"/>
            <linearGradient id="game-linear-gradient-14" gradientTransform="matrix(-1 0 0 1 128 0)" x1="48.617" x2="48.617"
                            xlinkHref="#game-linear-gradient-6" y1="47.667" y2="61.417"/>
            <linearGradient id="game-linear-gradient-15" x1="36.208" x2="36.208" xlinkHref="#game-linear-gradient-6" y1="53.667"
                            y2="142.336"/>
            <linearGradient id="game-linear-gradient-16" x1="91.776" x2="91.776" xlinkHref="#game-linear-gradient-6" y1="63.75"
                            y2="136.497"/>
            <linearGradient id="game-linear-gradient-17" x1="64" x2="64" xlinkHref="#game-linear-gradient-3" y1="53" y2="29.217"/>
            <linearGradient id="game-linear-gradient-18" gradientUnits="userSpaceOnUse" x1="64.001" x2="64.001" y1="9.375"
                            y2="30.926">
                <stop offset="0" stopColor="#f1ddb5"/>
                <stop offset="1" stopColor="#ffcd9f"/>
            </linearGradient>
            <linearGradient id="game-linear-gradient-19" x1="64.033" x2="64.033" xlinkHref="#game-linear-gradient-6" y1="90.667"
                            y2="136.578"/>
            <linearGradient id="game-linear-gradient-20" x1="63.998" x2="63.998" xlinkHref="#game-linear-gradient-6" y1="55.167"
                            y2="120.002"/>
            <linearGradient id="game-linear-gradient-21" x1="12.062" x2="12.062" xlinkHref="#game-linear-gradient-2" y1="82.75"
                            y2="-4.866"/>
            <linearGradient id="game-linear-gradient-22" x1="115.938" x2="115.938" xlinkHref="#game-linear-gradient-2" y1="82.75"
                            y2="-4.866"/>
            <path
                d="m99.341 111.019c-2.078-3.407-6.854-9.357-17.1-15.037-2.834-1.579-5.91-2.109-8.135-4.078h-20.214c-2.236 1.969-5.3 2.5-8.145 4.078-10.234 5.68-15.01 11.63-17.088 15.037-2.425 3.992-.189 6.145 3.285 8.114 3.58 2.034 14.59 7.393 32.056 7.393s28.476-5.359 32.056-7.393c3.463-1.969 5.71-4.122 3.285-8.114z"
                fill="url(#game-linear-gradient)"/>
            <path
                d="m89.31 100.516v.011c-.01 9.739-9.49 9.461-12.03 9.214a.975.975 0 0 0 -.94.443c-.98 1.412-4.33 5.194-12.34 5.194s-11.36-3.782-12.34-5.194a.975.975 0 0 0 -.94-.443c-2.54.247-12.03.525-12.03-9.225a63.061 63.061 0 0 1 7.06-4.535c2.84-1.577 5.91-2.112 8.14-4.081h20.22c2.22 1.969 5.3 2.5 8.13 4.081a63.764 63.764 0 0 1 7.07 4.535z"
                fill="url(#game-linear-gradient-2)"/>
            <path
                d="m64 116.18c-8.239 0-12.034-3.655-13.4-5.454-2.241.181-7.129.165-10.387-2.8a9.885 9.885 0 0 1 -3.021-7.666 1.5 1.5 0 1 1 3 0 6.933 6.933 0 0 0 2.04 5.447c2.585 2.353 7.044 2.132 8.348 2.01a2.488 2.488 0 0 1 2.32 1.083c.928 1.3 3.91 4.382 11.1 4.382s10.171-3.086 11.119-4.412a2.5 2.5 0 0 1 2.318-1.05c1.289.123 5.744.341 8.33-2.009a6.935 6.935 0 0 0 2.043-5.441 1.5 1.5 0 0 1 1.5-1.5 1.5 1.5 0 0 1 1.5 1.5 9.889 9.889 0 0 1 -3.026 7.669c-3.267 2.969-8.171 2.971-10.384 2.8-1.4 1.823-5.2 5.441-13.4 5.441zm-13.561-5.67.01.013zm27.142-.031-.014.02z"
                fill="url(#game-linear-gradient-3)"/>
            <path
                d="m113.59 66.04c-1.98-.23-4.19-.57-6.55-.98a.037.037 0 0 0 -.03-.01c-13.68-2.41-32.47-7.3-43.01-7.3v-19.54c11.63 0 34.41 1.64 47.42 2.19 1.16.06 2.23.1 3.22.13 16.1.51 12.86 27.12-1.05 25.51z"
                fill="url(#game-linear-gradient-4)"/>
            <path
                d="m64 38.21v19.54c-10.54 0-29.33 4.89-43.01 7.3-2.37.42-4.59.76-6.58.99-13.91 1.61-17.15-25-1.05-25.51.99-.03 2.06-.07 3.22-.13 13.01-.55 35.79-2.19 47.42-2.19z"
                fill="url(#game-linear-gradient-5)"/>
            <path
                d="m97.454 45.8s-14.454-11.175-33.454-11.175-33.454 11.175-33.454 11.175a113.809 113.809 0 0 0 .331 12.965h66.246a113.809 113.809 0 0 0 .331-12.965z"
                fill="url(#game-linear-gradient-6)"/>
            <path
                d="m34.871 55.836a5.33 5.33 0 0 0 -9.461 2.3c-1.928 7.588 6.19 11.336 5.51 18.164-.524 5.314 5.1 7.522 7.767 4.318z"
                fill="url(#game-linear-gradient-7)"/>
            <path
                d="m36.271 66.048a1.5 1.5 0 0 1 -1.459-1.161c-.252-1.083-2.973-2.443-4.2-2.652a1.5 1.5 0 0 1 .5-2.957c1.462.248 5.93 1.945 6.622 4.931a1.5 1.5 0 0 1 -1.122 1.8 1.523 1.523 0 0 1 -.341.039z"
                fill="#ff6d77"/>
            <path
                d="m96.009 12.005c-9.222-9.618-27.482-9.44-32.009-9.44s-22.787-.178-32.009 9.44c-6.408 6.682-4.851 17.068-3.284 27.37h70.586c1.567-10.302 3.124-20.688-3.284-27.37z"
                fill="url(#game-linear-gradient-8)"/>
            <path
                d="m102.59 58.133a5.33 5.33 0 0 0 -9.461-2.3l-3.816 24.789c2.668 3.2 8.291 1 7.767-4.318-.675-6.832 7.438-10.58 5.51-18.171z"
                fill="url(#game-linear-gradient-9)"/>
            <path
                d="m92.8 45.349q-28.791-16.363-57.59 0c-.11 1.809-.353 9.682-.342 10.487.267 18.658 2.383 27.988 12.809 35.014 4.938 3.331 10.223 6.924 16.323 6.924s11.385-3.593 16.328-6.924c10.426-7.026 12.542-16.356 12.809-35.014.011-.805-.232-8.678-.337-10.487z"
                fill="url(#game-linear-gradient-10)"/>
            <path
                d="m66.191 70.54a1.5 1.5 0 0 1 -1.191-.592 3.986 3.986 0 0 0 -1.005-.182 3.912 3.912 0 0 0 -.99.181 1.5 1.5 0 0 1 -2.694-.907c0-2.213 3.647-2.274 3.684-2.274s3.7.061 3.7 2.274a1.5 1.5 0 0 1 -1.504 1.5z"
                fill="#ff6d77"/>
            <path
                d="m41.717 57.514a10.6 10.6 0 0 0 2.951.358c.471-.026 3.342-.072 3.342-.072a10.352 10.352 0 0 1 3.655.577 6.1 6.1 0 0 1 3.01 2.629 1.18 1.18 0 0 1 -1.923 1.354l-.039-.047a7.1 7.1 0 0 0 -.919-.96 4.413 4.413 0 0 0 -1.071-.616 10.594 10.594 0 0 0 -2.858-.55s-2.658-.187-3.292-.261a4.886 4.886 0 0 1 -3.267-1.745.417.417 0 0 1 .064-.585.424.424 0 0 1 .347-.082z"
                fill="url(#game-linear-gradient-11)"/>
            <path
                d="m40.644 48.925a10.29 10.29 0 0 0 3.9-.09 13.54 13.54 0 0 1 2.192-.138 20.95 20.95 0 0 1 2.163.12 16.358 16.358 0 0 1 4.28 1.015 13.213 13.213 0 0 1 3.86 2.311 1.113 1.113 0 0 1 -1.446 1.693c-.017-.014-.037-.034-.053-.048a12.589 12.589 0 0 0 -6.877-3.2 15.812 15.812 0 0 0 -3.937-.185 21.166 21.166 0 0 1 -2.148.151 9.9 9.9 0 0 1 -2.221-.274h-.008a.695.695 0 0 1 .3-1.357z"
                fill="url(#game-linear-gradient-12)"/>
            <path
                d="m86.283 57.514a10.6 10.6 0 0 1 -2.951.358c-.471-.026-3.342-.072-3.342-.072a10.352 10.352 0 0 0 -3.655.577 6.1 6.1 0 0 0 -3.01 2.629 1.18 1.18 0 0 0 1.923 1.354l.039-.047a7.1 7.1 0 0 1 .919-.96 4.413 4.413 0 0 1 1.071-.616 10.594 10.594 0 0 1 2.858-.55s2.658-.187 3.292-.261a4.886 4.886 0 0 0 3.267-1.745.417.417 0 0 0 -.064-.585.424.424 0 0 0 -.347-.082z"
                fill="url(#game-linear-gradient-13)"/>
            <path
                d="m87.356 48.925a10.29 10.29 0 0 1 -3.9-.09 13.54 13.54 0 0 0 -2.192-.138 20.95 20.95 0 0 0 -2.163.12 16.358 16.358 0 0 0 -4.28 1.015 13.213 13.213 0 0 0 -3.86 2.311 1.113 1.113 0 0 0 1.446 1.693c.017-.014.037-.034.053-.048a12.589 12.589 0 0 1 6.877-3.2 15.812 15.812 0 0 1 3.937-.185 21.166 21.166 0 0 0 2.148.151 9.9 9.9 0 0 0 2.221-.274h.008a.695.695 0 0 0 -.3-1.357z"
                fill="url(#game-linear-gradient-14)"/>
            <path
                d="m58.135 76.781a.323.323 0 0 1 .4-.448 2.635 2.635 0 0 0 1.525 0l1.865-.673a2.364 2.364 0 0 1 2.075.426 2.366 2.366 0 0 1 2.074-.421l1.865.673a2.635 2.635 0 0 0 1.525 0 .322.322 0 0 1 .4.448 6.526 6.526 0 0 1 -11.724 0z"
                fill="#fff"/>
            <path
                d="m91.712 66.048a1.529 1.529 0 0 1 -.34-.039 1.5 1.5 0 0 1 -1.122-1.8c.692-2.986 5.16-4.683 6.622-4.931a1.5 1.5 0 1 1 .5 2.957c-1.229.209-3.95 1.569-4.2 2.652a1.5 1.5 0 0 1 -1.46 1.161z"
                fill="#ff6d77"/>
            <path
                d="m33.468 115.984a1.5 1.5 0 0 1 -1.144-2.468c7.337-8.667 5.141-26.793 3.2-42.786-1.267-10.463-2.465-20.347-1.186-27.5a1.5 1.5 0 1 1 2.953.53c-1.2 6.7-.028 16.37 1.212 26.605 2.109 17.412 4.291 35.417-3.893 45.083a1.494 1.494 0 0 1 -1.142.536z"
                fill="url(#game-linear-gradient-15)"/>
            <path
                d="m94.516 115.984a1.5 1.5 0 0 1 -1.146-.531c-8.183-9.665-6-27.671-3.891-45.083 1.24-10.234 2.412-19.9 1.211-26.605a1.5 1.5 0 1 1 2.954-.53c1.279 7.148.081 17.032-1.187 27.5-1.938 15.992-4.135 34.118 3.2 42.785a1.5 1.5 0 0 1 -1.143 2.468z"
                fill="url(#game-linear-gradient-16)"/>
            <path
                d="m99.034 39.317a99.505 99.505 0 0 0 -35.034-6.605 102.972 102.972 0 0 0 -35.034 6.6 2.093 2.093 0 0 0 -1.335 2.11l.257 3.54a2.092 2.092 0 0 0 2.8 1.782 106.662 106.662 0 0 1 33.312-5.804 106.662 106.662 0 0 1 33.312 5.809 2.092 2.092 0 0 0 2.8-1.782l.257-3.54a2.09 2.09 0 0 0 -1.335-2.11z"
                fill="url(#game-linear-gradient-17)"/>
            <path
                d="m66.107 15.446a3.953 3.953 0 0 0 -4.214 0c-10.093 6.339-12.579 20.989 2.107 20.989s12.205-14.65 2.107-20.989z"
                fill="url(#game-linear-gradient-18)"/>
            <circle cx="64.033" cy="26.053" fill="#fff" r="4.623"/>
            <path
                d="m64.033 123.669c3.851 0 1.706-7.216 4.2-13.514 2.592-6.553 4.744-10.676 4.2-14.382-.794-5.386-6.2-2.5-8.394-2.5s-7.6-2.886-8.393 2.5c-.547 3.706 1.6 7.829 4.2 14.382 2.481 6.298.336 13.514 4.187 13.514z"
                fill="url(#game-linear-gradient-19)"/>
            <path
                d="m81.971 121.151a1.5 1.5 0 0 1 -1.254-.675c-4.269-6.486-5.279-24.656-5.817-34.416-.124-2.23-.221-3.992-.32-4.8a9.442 9.442 0 0 0 -3.615-6.051 5.9 5.9 0 0 0 -4.82-1.035 10.494 10.494 0 0 1 -4.287 0 5.9 5.9 0 0 0 -4.82 1.035 9.442 9.442 0 0 0 -3.615 6.05c-.1.81-.2 2.572-.32 4.8-.543 9.761-1.553 27.931-5.821 34.417a1.5 1.5 0 0 1 -2.506-1.649c3.824-5.81 4.837-24.127 5.324-32.934.131-2.374.227-4.089.337-5a12.485 12.485 0 0 1 4.845-8.125 8.917 8.917 0 0 1 7.181-1.536 7.49 7.49 0 0 0 3.063 0 8.914 8.914 0 0 1 7.181 1.536 12.485 12.485 0 0 1 4.848 8.132c.11.908.2 2.624.338 5 .488 8.8 1.5 27.124 5.33 32.934a1.5 1.5 0 0 1 -1.252 2.324z"
                fill="url(#game-linear-gradient-20)"/>
            <path
                d="m20.99 65.05c-2.37.42-4.59.76-6.58.99-13.91 1.61-17.15-25-1.05-25.51.99-.03 2.06-.07 3.22-.13-4.12 6.85.52 13.859 3.42 17.11a7.8 7.8 0 0 1 .99 7.54z"
                fill="url(#game-linear-gradient-21)"/>
            <path
                d="m113.59 66.04c-1.98-.23-4.19-.57-6.55-.98a.037.037 0 0 0 -.03-.01 7.8 7.8 0 0 1 .99-7.54c2.9-3.25 7.54-10.26 3.42-17.11 1.16.06 2.23.1 3.22.13 16.1.51 12.86 27.12-1.05 25.51z"
                fill="url(#game-linear-gradient-22)"/>
        </g>
    </svg>
}






