import gift from './gift'
import home from './home'
import homeHighlight from './homeHighlight'
import hot from './hot'
import live from './live'
import liveSelected from './liveSelected'
import game from './game'
import gameHighlight from './gameHighlight'
import shortVideo from './shortVideo'
import shortVideoHighlight from './shortVideoHighlight'
import search from './search'
import like from './like'
import history from './history'
import user from './user'
import logo from './logo'
import newyearHome from './newyearHome'
import newyearHot from './newyearHot'
import newyearGame from './newyearGame'
import newyearShortVideo from './newyearShortVideo'
import videoLike from './videoLike'
import videoPlay from './videoPlay'
import channel from './channel'
import mine from './mine'
import mineHighlight from './mineHighlight'
import rank from './rank'
import rankHighlight from './rankHighlight'
import lastestNotice from './lastestNotice'
import liveGuide from './liveGuide'
import back from './back'
import loading from './loading'
import vipCard from './vipcard'
import userpic from "./userpic";

export default {
    gift,
    back,
    home,
    homeHighlight,
    hot,
    live,
    liveSelected,
    game,
    gameHighlight,
    shortVideo,
    shortVideoHighlight,
    search,
    loading,
    vipCard,
    like,
    history,
    user,
    logo,
    newyearHome,
    newyearHot,
    newyearGame,
    newyearShortVideo,
    videoLike,
    videoPlay,
    channel,
    mine,
    mineHighlight,
    rank,
    rankHighlight,
    lastestNotice,
    liveGuide,
    userpic
}


