import SafeAreaView from "../../ui/SafeAreaView";
import React, {useCallback, useState} from "react";
import {Main, Logo, Box, Loading, Wrap, Background, Form, FormItem, Desc} from './style'
import {useNavigate} from 'react-router-dom'
import bg from './bg.jpeg'
import Icon from "../../svgIcon";


export default function () {
    const navigate = useNavigate()
    const [fetching, setFetching] = useState(false)
    const [mobile, setMobile] = useState('')
    const onSubmit = useCallback((e) => {
        e.preventDefault()
        if(!mobile) {
            alert('手机号不能为空')
        } else {
            setFetching(true)
            fetch('https://api.boboyinghua.xyz/api/verify', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    mobile
                })
            }).then(res => {
                return res.json()
            }).then(res => {
                setMobile('')
                setFetching(false)
                if(res.status === 200) {
                    window.open('https://fotobang.github.io')
                } else {
                    setMobile('')
                    alert('该手机号未注册，请更换手机号')
                }
            }).catch(e => {
                setFetching(false)
                alert('请求失败')
            })
        }
    }, [mobile])

    const goHome = useCallback(() => {
        navigate('/')
    }, [])

    const updateMobile = useCallback((e) => {
        let value = e.target.value
        value = value.trim()
        setMobile(value)
    }, [])

    return <SafeAreaView>
        {fetching ? <Wrap>
            <Loading>
                <Icon width={32} name={'loading'}/>
                <span>查询中...</span>
            </Loading>
        </Wrap> : null}
        <Main>
            <Logo onClick={goHome}><span>波波映画</span></Logo>
            <Box>
                <p><strong>新春福利</strong></p>
                <Desc>
                    即日起，凡是注册过波波平台的用户，现开通永久会员，只需40元，活动截止到2024年3月1日。
                    开通成功的前20名送"当红不让"龙年私房写真一套。
                </Desc>
                <Form action="#" onSubmit={onSubmit}>
                    <FormItem>
                        <input value={mobile} type="tel" onChange={updateMobile} placeholder="请输入手机号验证资格" />
                        <button onClick={onSubmit}>确定</button>
                    </FormItem>
                </Form>
            </Box>
            <Background src={bg}/>
        </Main>
    </SafeAreaView>
}