
export default [
    // {title: '洪崖洞夜景', src: _img},
    {title: '重庆爱乐之城', id: '65263760000000002101d519', src: 'https://www.freeimg.cn/i/2024/02/02/65bcb0ef2d487.jpeg'},
    {title: '重庆白象居', id: '65adedd6000000002c03b32c', src: 'https://www.freeimg.cn/i/2024/02/02/65bcbcc2a01e3.jpeg'},
    {title: '重庆白象街', id: '65a3b11a0000000010010f35', src: 'https://www.freeimg.cn/i/2024/02/02/65bcc257d8ca9.jpeg'},
    // {title: '重庆云端之眼', id: '6523fdbc000000001e03c9e2', src: 'https://www.freeimg.cn/i/2024/02/02/65bcc3d078bbb.png'},
    {title: '重庆洪崖洞', id: '6558a8c5000000003202c508', src: 'https://www.freeimg.cn/i/2024/02/02/65bccd0065dd4.jpeg'},
    {title: '重庆洪崖洞', id: '63f9f4690000000013012a89', src: 'https://www.freeimg.cn/i/2024/02/02/65bccdde103cc.jpeg'},
    {title: '异域风', id: '64103251000000001203c8f7', src: 'https://www.freeimg.cn/i/2024/02/02/65bccea0309aa.jpeg'},
    {title: '私房写真', id: '6524e57e000000001a020c90', src: 'https://www.freeimg.cn/i/2024/02/03/65bd1409c4a17.jpeg'},
]