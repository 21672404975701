import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
        <filter x="-9.6%" y="-10.4%" width="119.2%" height="120.8%" filterUnits="objectBoundingBox" id="filter-1">
            <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.551837404   0 0 0 0 0.394229925   0 0 0 0 0.961616848  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
            <stop stopColor="#9167FF" offset="0%"></stop>
            <stop stopColor="#5114CC" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="93.969796%" id="linearGradient-3">
            <stop stopColor="#B193FF" offset="0%"></stop>
            <stop stopColor="#6727E9" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
            <stop stopColor="#87F8FF" stopOpacity="0.678949956" offset="0%"></stop>
            <stop stopColor="#31E0EA" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="新秀满月礼" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="游戏tab-icon">
            <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="28" height="28"></rect>
            <g id="编组-5" filter="url(#filter-1)" transform="translate(1.000000, 2.000000)">
                <path d="M17.7012471,0.308679317 C18.2473941,0.226569726 18.7566966,0.602746309 18.8388062,1.14889323 C18.8591015,1.28388609 18.8692916,1.42020563 18.8692916,1.55671559 C18.8692916,3.01557298 17.7304554,4.20844092 16.2933021,4.29480033 L16.1262007,4.29980652 L10.1046674,4.29980652 C9.68999548,4.29980652 9.35383745,4.63596455 9.35383745,5.0506365 C9.35383745,5.43075245 9.63630357,5.7448949 10.0027842,5.79461228 L10.1046674,5.80146648 L10.8477844,5.80146648 C12.7131162,5.80146648 14.2370392,6.26321169 14.3365615,8.10371034 L14.3417314,8.29541343 L14.3417314,9.87752853 C14.3417314,10.4298133 13.8940161,10.8775285 13.3417314,10.8775285 C12.8288955,10.8775285 12.4062242,10.4914883 12.3484591,9.99414965 L12.3417314,9.87752853 L12.3417314,8.29541343 C12.3417314,7.51886367 11.7492439,7.88069678 10.9916615,7.80830535 L10.8477844,7.80146648 L10.1046674,7.80146648 C8.58542598,7.80146648 7.35383745,6.56987794 7.35383745,5.0506365 C7.35383745,3.58766325 8.49588662,2.39142988 9.93709454,2.30482683 L10.1046674,2.29980652 L16.1262007,2.29980652 C16.5365984,2.29980652 16.8692916,1.96711338 16.8692916,1.55671559 L16.8672241,1.50132286 L16.8610332,1.44623836 C16.7789236,0.900091439 17.1551002,0.390788907 17.7012471,0.308679317 Z" id="路径-2备份" stroke="#A884FB" strokeWidth="0.2" fill="#976BFF" fillRule="nonzero"></path>
                <path d="M12.3073993,8.28959108 L14.3034663,8.28959108 C14.6348372,8.28959108 14.9034663,8.55822023 14.9034663,8.88959108 L14.9034663,11 L14.9034663,11 L11.7073993,11 L11.7073993,8.88959108 C11.7073993,8.55822023 11.9760284,8.28959108 12.3073993,8.28959108 Z" id="矩形" fill="#31E1EB"></path>
                <path d="M18.8867925,9.1 C20.8234362,9.1 22.5767381,9.88497999 23.8458791,11.1541209 C25.11502,12.4232619 25.9,14.1765638 25.9,16.1132075 L25.9,16.1132075 L25.9,16.7163749 C25.8999772,18.4570075 25.1944339,20.0328544 24.053738,21.173542 C22.9130457,22.3142261 21.3372033,23.0197536 19.5965761,23.0197536 C19.1913239,23.0197536 18.7872537,22.9806729 18.3940863,22.9040841 C17.1848211,22.6147296 16.3081152,22.2962075 15.467713,21.8836489 C13.692848,20.9425174 12.082324,20.9291884 10.7229072,21.6177545 C9.79437555,22.0880704 8.98404414,22.4220183 8.2950647,22.6180631 C6.4630838,23.0704206 4.68468695,22.781387 3.25440627,21.9542915 C1.80291689,21.1149315 0.710398464,19.7216175 0.284988449,17.9892512 C0.162130041,17.4889438 0.100029137,16.9756505 0.1,16.4604847 C0.1,14.3702371 0.870374017,12.5437231 2.12016492,11.2329156 C3.39168515,9.89931792 5.15911362,9.1 7.11320755,9.1 L7.11320755,9.1 Z" id="矩形" stroke="url(#linearGradient-3)" strokeWidth="0.2" fill="url(#linearGradient-2)"></path>
                <rect id="矩形" fill="url(#linearGradient-4)" transform="translate(6.396226, 15.459119) rotate(-270.000000) translate(-6.396226, -15.459119) " x="5.39622642" y="11.9433962" width="2" height="7.03144654" rx="0.981132075"></rect>
                <rect id="矩形" fill="#31E0EA" x="5.39622642" y="11.9433962" width="2" height="7.03144654" rx="0.981132075"></rect>
                <circle id="椭圆形" fill="#FF77DC" cx="19.3773585" cy="13.1698113" r="1.22641509"></circle>
                <circle id="椭圆形备份-4" fill="#31E0EA" cx="16.9245283" cy="15.6226415" r="1.22641509"></circle>
                <circle id="椭圆形备份-5" fill="#31E0EA" cx="19.3773585" cy="18.0754717" r="1.22641509"></circle>
                <circle id="椭圆形备份-6" fill="#FF77DC" cx="21.8301887" cy="15.6226415" r="1.22641509"></circle>
            </g>
        </g>
    </g>
    </svg>
}









