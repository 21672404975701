import styled from "@xstyled/styled-components";

export const Wrap = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  z-index: 1;
`

export const Main = styled.div`
  padding: 0 5px;
  display: flex;
  flex: 1;
  height: 100%;
`

export const Fixed = styled.div`
  position: fixed;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: #fff;
`

export const BackBtn = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

export const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`