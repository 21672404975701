import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={'0 0 512 512'}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g xmlns="http://www.w3.org/2000/svg">
            <path
                fill={fill}
                d="m456.832 0h-401.665c-30.419 0-55.167 24.748-55.167 55.167v401.667c0 30.418 24.748 55.166 55.167 55.166h401.666c30.42 0 55.168-24.748 55.168-55.166v-401.667c-.001-30.419-24.749-55.167-55.169-55.167zm25.168 456.834c0 13.876-11.29 25.166-25.168 25.166h-401.665c-13.877 0-25.167-11.29-25.167-25.166v-401.667c0-13.877 11.29-25.167 25.167-25.167h401.666c13.877 0 25.167 11.29 25.167 25.167z"/>
            <path
                fill={fill}
                d="m367.467 222.927-155.362-89.697c-7.728-4.462-15.451-6.724-22.955-6.724-20.213 0-34.331 16.365-34.331 39.797v179.395c0 23.433 14.117 39.799 34.33 39.799 7.504 0 15.228-2.263 22.956-6.725l155.363-89.697c13.885-8.016 21.848-20.07 21.848-33.073-.001-13.003-7.964-25.058-21.849-33.075zm-15 40.166-155.363 89.698c-4.076 2.354-6.733 2.705-7.955 2.705-3.767 0-4.33-6.139-4.33-9.799v-179.394c0-3.659.563-9.797 4.331-9.797 1.222 0 3.879.352 7.955 2.705l155.363 89.697c5.417 3.128 6.849 6.008 6.848 7.094-.002 1.084-1.432 3.964-6.849 7.091z"/>
        </g>
    </svg>
}











