import React, { Component } from 'react';
import iconLive from './live-normal.svg'

export default ({ style, fill, width, className, viewBox, firstColor, secondColor }) => {
    return <img src={iconLive} alt=""/>
}






