import Home from './home'
import User from './user'
import Detail from './detail'
import Search from './search'
import Product from './product'

export default {
    'home': Home,
    'detail': Detail,
    'user': User,
    'product': Product,
    'search': Search
}
