import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <filter x="-8.1%" y="-8.9%" width="116.1%" height="117.9%" filterUnits="objectBoundingBox" id="mineHighlight-filter-1">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0.854901961   0 0 0 0 0.294117647   0 0 0 0 0.968627451  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <linearGradient x1="60.5896792%" y1="75.4282058%" x2="41.3194444%" y2="50%" id="mineHighlight-linearGradient-2">
                <stop stopColor="#24DFFF" stopOpacity="0.0317690122" offset="0%"/>
                <stop stopColor="#24DFFF" offset="100%"/>
            </linearGradient>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="mineHighlight-linearGradient-3">
                <stop stopColor="#EF82FC" stopOpacity="0.579190341" offset="0%"/>
                <stop stopColor="#FF328B" offset="100%"/>
            </linearGradient>
            <rect id="mineHighlight-path-4" x="13.7942286" y="1" width="10" height="24" rx="5"/>
        </defs>
        <g id="icon/tabbar/mine-highlight" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g filter="url(#mineHighlight-filter-1)" transform="translate(-1.000000, 0.000000)">
                <rect id="矩形" fill="#110036" opacity="0" x="2" y="0" width="28" height="28"/>
                <g id="编组" transform="translate(0.500000, 1.000000)">
                    <rect id="矩形" fill="url(#mineHighlight-linearGradient-2)" transform="translate(10.330127, 13.000000) rotate(-30.000000) translate(-10.330127, -13.000000) " x="5.33012702" y="1" width="10" height="24" rx="5"/>
                    <g id="矩形备份" transform="translate(18.794229, 13.000000) scale(-1, 1) rotate(-30.000000) translate(-18.794229, -13.000000) ">
                        <use fill="url(#mineHighlight-linearGradient-3)" fillRule="evenodd" xlinkHref="#mineHighlight-path-4"/>
                        <rect stroke="#DA4BF7" strokeWidth="0.1" strokeLinejoin="square" x="13.8442286" y="1.05" width="9.9" height="23.9" rx="4.95"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}





