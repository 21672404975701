
import { th } from "@xstyled/system";


const theme = {
    colors: {
        // primary: "#E398E6",
        // darkBlue: "#2C1D3E",
        // bg: "#fff",
        // text: "#333",
        // link: "#006688",
        // navbarBg: th.color("primary"),
        // codeBg: th.color("primary"),

        // 前后顺序由使用频率决定
        tabBarBgColor:'#2C1054',
        textFirstColor:'#24DFFF',
        textSecondColor:'#ADB4EC',
        textThirdColor:'#696D91',
        textFourthColor:'#8085B2',
        textFifthColor:'#D9DDFF',
        bg:'#190C33',
        bg2nd: '#152451',
        bg3rd: '#ff338b',
        bg4rd: '#220D43',
        maskBg:'#110036',

        // user-center
        yellow1st: '#ffd677',
        red1st: '#ff338b',
        bgPrivilege: '#201042',
        userInfoBg: '#391e70',
        goodsWrapBg: '#290a54',
        goodBg: '#553287',
        goodBgActive: '#391e73',
        vipBorder: '#61379e',

        modes: {
            dark: {
                // bg: "#10171d",
                // text: "#dfdfdf",
                // link: th.color("primary"),
                // navbarBg: th.color("darkBlue"),
                // codeBg: th.color("darkBlue")
            }
        }
    }
};

export default theme;
