import chengshi from "./chengshi";
import minzu from "./minzu";
import sifang from "./sifang";
import cosplay from "./cosplay";
import qingxu from "./qingxu";
export default {
    chengshi,
    minzu,
    sifang,
    cosplay,
    qingxu
}