import styled from '@xstyled/styled-components';
import {th} from "@xstyled/system";

export const WarpperContent = styled.div`
    flex: 1;
    position: relative;
    overflow: hidden;
    margin: 5px;
`;
export const TabItem = styled.div`
    display: flex;
    font-size: ${props => props.active ? '22px' : '16px'};
    padding: 8px 16px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: ${props => props.active ? th.color("textFirstColor") : th.color("textThirdColor")};
    font-weight: 600;
    letter-spacing: 1px;
`;

