import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <linearGradient x1="99.8652645%" y1="50%" x2="4.68098109%" y2="50%" id="shortVideo-linearGradient-1">
                <stop stopColor="#696D91" offset="0%"/>
                <stop stopColor="#696D91" offset="100%"/>
            </linearGradient>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="shortVideo-linearGradient-2">
                <stop stopColor="#ADB4EC" offset="0%"/>
                <stop stopColor="#ADB4EC" offset="100%"/>
            </linearGradient>
            <rect id="shortVideo-path-3" x="7" y="2" width="18" height="24" rx="2"/>
        </defs>
        <g id="icon/tabbar/video-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.400000006">
            <g>
                <rect id="矩形" fill="#110036" opacity="0" x="0" y="0" width="28" height="28"/>
                <rect id="矩形" fill="url(#shortVideo-linearGradient-1)" x="3" y="3" width="12" height="22" rx="2"/>
                <g id="矩形备份-9">
                    <use fill="url(#shortVideo-linearGradient-2)" fillRule="evenodd" xlinkHref="#shortVideo-path-3"/>
                    <rect stroke="#ADB4EC" strokeWidth="0.1" strokeLinejoin="square" x="7.05" y="2.05" width="17.9" height="23.9" rx="2"/>
                </g>
            </g>
        </g>
    </svg>
}












