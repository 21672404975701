import styled, {keyframes} from "@xstyled/styled-components";
import logo from '../../../logo.png'

export const Main = styled.div`
  position: relative;
  flex: 1;
  //padding-top: 50px;
  background-color: rgb(142, 5, 15);
  font-family: PingFangSC-light;
  p {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    text-align: center;
    font-size: 24px;
    color: #fff;
  }
`

export const Box = styled.div`
  margin-top: 60px;
`

export const Background = styled.img`
  max-width: 100%;
  //height: 100%;
  //background-image: url("https://www.freeimg.cn/i/2024/02/03/65be38123bc5e.jpeg");
  //background-size: contain;
  //background-repeat: no-repeat;
`

export const Desc = styled.div`
  font-size: 12px;
  padding: 0 70px;
  margin-top: 20px;
  color: #fff;
  font-weight: 400;
  font-family: "Noto Sans CJK SC", -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif;
`

export const Logo = styled.div`
  position: absolute;
  right: 10px;
  color: #fff;
  font-weight: bold;
  top: 10px;
  height: 30px;
  padding-right: 30px;
  background-image: url("${logo}");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    margin-right: 5px;
  }
`

export const Form = styled.form`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  input {
    height: 40px;
    border: 0;
    width: 200px;
    padding: 0 0 0 10px;
    outline: none;
    border-radius: 5px 0 0 5px;
    //border-radius: 20px;
    background-color: #521209;
    color: #fff;
  }
  button {
    height: 40px;
    border: 0;
    width: 80px;
    outline: none;
    color: #fff;
    border-radius: 0 5px 5px 0;
    background-color: #eb8d29;
  }
`

export const Wrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
`

const loading = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

export const Loading = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  flex-direction: column;
  font-size: 12px;
  svg {
    animation: ${loading} 3s linear infinite;
    margin-bottom: 10px;
  }
`

export const FormItem = styled.div`
  
`