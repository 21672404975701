import React, {Component} from 'react';
import Icons from './icons';

export default ({name = '',firstColor='',secondColor='', style = {}, fill = "#000", width = "100%", className = "", viewBox = "0 0 32 32"}) => {
    let Icon = Icons[name]
    if(!Icon){
        return <div></div>
    }
    return <Icon
        firstColor={firstColor}
        secondColor={secondColor}
        style={style}
        fill={fill}
        width={width}
        className={className}
        viewBox={viewBox}
    ></Icon>
}



