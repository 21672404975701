import Loading from '../Loading'
import {Wrap} from './style'
import {useCallback, useEffect, useState} from "react";
import React, {createElement} from 'react'

const loadImage = (src) => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => resolve(image)
        image.onerror = () => reject(new Error(''))
        image.src = src
        return image
    })
}

export default function (props) {
    const {
        src,
        style
    } = props
    const [cache, setCache] = useState(null)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    const fetchImg = useCallback(() => {
        loadImage(src).then(image => {
            setCache(image)
            setLoading(false)
        }).catch(e => {
            setError(true)
        })
    }, [src])

    const renderImage = useCallback(() => {
        if(loading) {
            return <Wrap><Loading/></Wrap>
        } else {
            return <Wrap style={style}><img src={cache.src} /> </Wrap>
        }
    }, [loading, cache, style])

    useEffect(() => {
        fetchImg()
    }, [fetchImg])

    return  renderImage()
}