import styled from "@xstyled/styled-components";

export const Main = styled.div`
  padding: 0 15px;
`

export const SearchBar = styled.form`
  display: flex;
  align-items: center;
  height: 40px;
  background: #eee;
  width: 100%;
  border-radius: 40px;
  margin-top: 20px;
  padding: 0 10px;
  box-sizing: border-box;
  input {
    border: 0;
    outline: 0;
    height: 100%;
    flex: 1;
    background: #eee;
    border-radius: 40px;
    padding: 0 0 0 10px;
  }
`

export const History = styled.div`
  margin-top: 20px;
  font-size: 14px;
`