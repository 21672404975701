import NavBar from "../../ui/NavBar";
import SafeAreaView from "../../ui/SafeAreaView";
import React, {useCallback, useState} from "react";
import {Form, FormItem, FormDesc, Button, Name} from './style'
import {useNavigate} from 'react-router-dom'


export default function () {
    const navigate = useNavigate()
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')


    const onSubmit = useCallback((e) => {
        e.preventDefault()
        if(!mobile) {
            alert('手机号不能为空')
        } else if(!password) {
            alert('密码不能为空')
        } else {
            navigate('/')
        }
    }, [mobile, password])

    const updateMobile = useCallback((e) => {
        let value = e.target.value
        setMobile(value)
    }, [])

    const updatePassword = useCallback((e) => {
        let value = e.target.value
        setPassword(value)
    }, [])

    return <SafeAreaView>
        <NavBar />
        <Name>波波映像</Name>
        <Form action="#" onSubmit={onSubmit}>
            <FormItem>
                <label>手机号</label>
                <input value={mobile} onChange={updateMobile} placeholder="请输入手机号码" type="text" />
            </FormItem>
            <FormItem>
                <label>密码</label>
                <input value={password} onChange={updatePassword} placeholder="请输入密码" type="text" />
            </FormItem>
            <FormDesc>
                <span>忘记密码</span>
                <span>注册账号</span>
            </FormDesc>
            <Button onClick={onSubmit}>登录</Button>
        </Form>
    </SafeAreaView>
}