import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <filter x="-8.9%" y="-8.9%" width="117.9%" height="117.9%" filterUnits="objectBoundingBox" id="gameHighlight-filter-1">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0.854901961   0 0 0 0 0.294117647   0 0 0 0 0.968627451  0 0 0 1 0"
                               type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <linearGradient x1="100%" y1="50%" x2="3.68190456%" y2="50%" id="gameHighlight-linearGradient-2">
                <stop stopColor="#24DFFF" stopOpacity="0.0317690122" offset="0%"/>
                <stop stopColor="#2FE3EB" offset="100%"/>
            </linearGradient>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="gameHighlight-linearGradient-3">
                <stop stopColor="#EF82FC" stopOpacity="0.579190341" offset="0%"/>
                <stop stopColor="#FF328B" offset="100%"/>
            </linearGradient>
            <path
                d="M22.5,3 L27,3 L27,3 C26.0009357,7.8544694 25.0018714,11.7089388 24.0028071,14.5634082 C23.1640637,16.9598179 22.3253203,19.3562277 21.4865769,21.7526374 C20.5961914,24.2965961 18.1952759,26 15.5,26 C13.0147186,26 11,23.9852814 11,21.5 L11,14.5 C11,8.14872538 16.1487254,3 22.5,3 Z"
                id="gameHighlight-path-4"/>
        </defs>
        <g id="icon/tabbar/game-highlight" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g filter="url(#gameHighlight-filter-1)">
                <rect id="矩形" fill="#110036" opacity="0" x="0" y="0" width="28" height="28"/>
                <path
                    d="M12.5,3 L17,3 L17,3 C17,11 15.997223,14.8650772 13.991669,14.5952315 C13.1566383,16.9810335 12.3216076,19.3668354 11.4865769,21.7526374 C10.5961914,24.2965961 8.19527593,26 5.5,26 C3.01471863,26 1,23.9852814 1,21.5 L1,14.5 C1,8.14872538 6.14872538,3 12.5,3 Z"
                    id="矩形" fill="url(#gameHighlight-linearGradient-2)" />
                <g id="矩形备份-8"
                   transform="translate(19.000000, 14.500000) scale(-1, 1) translate(-19.000000, -14.500000) ">
                    <use fill="url(#gameHighlight-linearGradient-3)" fillRule="evenodd" xlinkHref="#gameHighlight-path-4"/>
                    <path stroke="#DA4BF7" strokeWidth="0.1"
                          d="M26.9386514,3.05 L22.5,3.05 C19.3381698,3.05 16.4756698,4.3315849 14.4036274,6.40362736 C12.3315849,8.47566981 11.05,11.3381698 11.05,14.5 L11.05,21.5 C11.05,22.7288336 11.5480832,23.8413336 12.3533748,24.6466252 C13.1586664,25.4519168 14.2711664,25.95 15.5,25.95 C16.8370144,25.95 18.1010187,25.5275061 19.1411903,24.7895241 C20.1813619,24.0515421 20.9977007,22.9980721 21.439384,21.7361199 L23.9556142,14.5468907 C24.9499953,11.7058017 25.9442724,7.87348465 26.9386514,3.05 Z"
                          strokeLinejoin="square"/>
                </g>
            </g>
        </g>
    </svg>
}










