import styled from "@xstyled/styled-components";

export const Page = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  min-height: 0;
`

export const FixedBar = styled.div`
  height: 50px;
  width: 100%;
  flex-shrink: 0;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  span {
    font-size: 14px;
    margin-right: 10px;
  }
`

export const ListWrap = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ImgItem = styled.img`
  max-width: 100%;
  margin-top: 2px;
`

export const Book = styled.button`
  width: 80px;
  height: 100%;
  background-color: #61dafb;
  color: #fff;
  border: 0;
`