import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="首页" transform="translate(-240.000000, -14.000000)" fillRule="nonzero">
                <g id="SVG-Layer" transform="translate(240.000000, 14.000000)">
                    <g id="Group">
                        <path
                            d="M7.00741935,20.5190744 C11.0393652,23.707025 16.8686334,23.1762095 20.2558755,19.3126648 C23.6431177,15.4491201 23.3961999,9.61261387 19.6946938,6.04775836 C15.9931876,2.48290285 10.1398315,2.44434264 6.39149194,5.96012072 L9.05229839,8.61557344 C9.58068548,9.14289738 9.20641129,10.0442656 8.45891129,10.0442656 L0.838709677,10.0442656 C0.375503113,10.0442656 9.31154795e-17,9.66951802 0,9.20724346 L0,1.60237425 C0,0.85637827 0.903185484,0.482857143 1.43157258,1.01018109 L4.02004032,3.59344064 C6.43604792,1.28323509 9.65400771,-0.0044708575 13,-4.64682275e-17 C20.1714919,-4.64682275e-17 25.9868952,5.79533199 26,12.9492555 C26.0131048,20.1031791 20.1720161,25.9424547 13.0094355,25.9476861 C10.0370985,25.9542183 7.15301361,24.9399297 4.84145161,23.0751308 C4.56476438,22.8509968 4.3963051,22.5202705 4.37791604,22.1650983 C4.35952698,21.8099261 4.49293357,21.4636335 4.745,21.2122334 L5.33576613,20.6226559 C5.78564174,20.1691905 6.50479218,20.1246294 7.00741935,20.5190744 Z"
                            id="Path" fill={firstColor || "#DBDFE2"}></path>
                        <path
                            d="M14.6774194,7.5331992 L14.6774194,12.9921529 L16.8108871,14.6478873 C17.0742778,14.8523224 17.2455015,15.1528086 17.2868884,15.4832378 C17.3282754,15.813667 17.236435,16.14697 17.0315726,16.4098189 L16.5168145,17.0700201 C16.3119673,17.3328808 16.0108753,17.5037601 15.6797799,17.5450637 C15.3486845,17.5863674 15.0147095,17.4947118 14.7513306,17.2902616 L11.3225806,14.6295775 L11.3225806,7.5331992 C11.3225806,6.83978736 11.8858353,6.277666 12.5806452,6.277666 L13.4193548,6.277666 C14.1141647,6.277666 14.6774194,6.83978736 14.6774194,7.5331992 Z"
                            id="Path" fill={secondColor || "#ADB5BD"}></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}











