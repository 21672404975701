import styled from "@xstyled/styled-components";

export const Page = styled.div`
  flex: 1 1 0px;
  min-height: 0px;
  overflow: hidden;
`

export const UserWrap = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 15px;
`

export const UserImg = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #dddddd;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UserName = styled.span`
  font-size: 18px;
`

export const List = styled.ul`
  list-style: none;
  padding: 0 15px;
  li {
    height: 40px;
    //border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    position: relative;
    :after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      height: 1px;
      width: 100%;
      background: #ccc;
      transform: scaleY(-1)
    }
  }
`