export default [
    {
        title: '城市旅拍',
        id: 'chengshi'
    },
    {
        title: '民族古风',
        id: 'minzu'
    },
    {
        title: '私房写真',
        id: 'sifang'
    },
    {
        title: 'COSPLAY',
        id: 'cosplay'
    },
    {
        title: '情绪片',
        id: 'qingxu'
    },
]