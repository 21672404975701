import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <linearGradient x1="99.8652645%" y1="50%" x2="4.68098109%" y2="50%" id="mine-linearGradient-1">
                <stop stopColor="#696D91" offset="0%"/>
                <stop stopColor="#696D91" offset="100%"/>
            </linearGradient>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="mine-linearGradient-2">
                <stop stopColor="#ADB4EC" offset="0%"/>
                <stop stopColor="#ADB4EC" offset="100%"/>
            </linearGradient>
            <rect id="mine-path-3" x="13.7942286" y="1" width="10" height="24" rx="5"/>
        </defs>
        <g id="icon/tabbar/mine-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
           opacity="0.400000006">
            <g transform="translate(-1.000000, 0.000000)">
                <rect id="矩形" fill="#110036" opacity="0" x="2" y="0" width="28" height="28"/>
                <g id="编组" transform="translate(0.500000, 1.000000)">
                    <rect id="矩形" fill="url(#mine-linearGradient-1)"
                          transform="translate(10.330127, 13.000000) rotate(-30.000000) translate(-10.330127, -13.000000) "
                          x="5.33012702" y="1" width="10" height="24" rx="5"/>
                    <g id="矩形备份"
                       transform="translate(18.794229, 13.000000) scale(-1, 1) rotate(-30.000000) translate(-18.794229, -13.000000) ">
                        <use fill="url(#mine-linearGradient-2)" fillRule="evenodd" xlinkHref="#mine-path-3"/>
                        <rect stroke="#ADB4EC" strokeWidth="0.1" strokeLinejoin="square" x="13.8442286" y="1.05"
                              width="9.9" height="23.9" rx="4.95"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}





