import { th } from '@xstyled/system'
import styled from '@xstyled/styled-components';

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
`;

export const Main = styled.main`
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    position: relative;
    order: 1;
    z-index: 1;
`;

export const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: stretch;
    position: relative;
    z-index: 1;
`;

export const NavWrap = styled.nav`
    position: ${props => props.hidden ? 'absolute' : null};
    bottom: ${props => props.hidden ? '-50px' : null};
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: stretch;
    min-height: 50px;
    min-height: calc(env(safe-area-inset-bottom) + 50px)
`;

export const NavBottom = styled.div`
    display: flex;
    align-items: stretch;
    flex-shrink: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: ${props => props.hidden ? '-50px' : 0};
    height: 50px;
    margin-left: env(safe-area-inset-left);
    padding-bottom: env(safe-area-inset-bottom);
    margin-right: env(safe-area-inset-right);
    background-color: ${props => props.barTintColor};
    transition: all 0.3s ease;
`;

export const NavBottomText = styled.p`
    font-size: 10px;
    transform: scale(0.8);
    margin: 3px 0 0;
    line-height: 1;
    text-align: center;
    color:${props => props.selected ? th('colors.textFirstColor') : th('colors.textFourthColor')};
`;

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    width: 100%
`;

export const TabItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%
`
