import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={'0 0 512 512'}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            fill={fill}
            d="m487.097656 258.816406c14.933594-9.3125 24.902344-25.886718 24.902344-44.746094 0-29.0625-23.640625-52.703124-52.695312-52.703124l-123.46875-.007813c11.292968-45.167969 10.820312-42.585937 10.820312-45.136719v-63.640625c0-28.992187-23.585938-52.582031-52.578125-52.582031s-52.578125 23.589844-52.578125 52.582031v43.671875l-56.503906 76.5625-33.742188 12.222656v-7.679687c0-8.285156-6.71875-15-15-15h-121.253906c-8.285156 0-15 6.714844-15 15v300.664063c0 8.285156 6.714844 15 15 15h121.253906c8.28125 0 15-6.714844 15-15v-15.117188c38.984375 29.210938 38.46875 30.070312 45.121094 30.070312h217.835938c42.808593 0 67.742187-48.640624 42.824218-83.355468 26.078125-16.261719 32.664063-50.832032 15.03125-75.402344 26.078125-16.261719 32.664063-50.832031 15.03125-75.402344zm-365.84375 204.207032h-91.253906v-270.664063h91.253906zm292.957032-.046876h-212.839844l-50.117188-37.558593v-208.472657c52.148438-18.886718 51.585938-17.785156 55.1875-22.660156l62.128906-84.1875c3.90625-5.296875 2.929688-4.820312 2.929688-57.515625 0-12.453125 10.128906-22.582031 22.578125-22.582031s22.578125 10.128906 22.578125 22.582031v61.792969l-14.582031 58.34375c-2.363281 9.453125 4.792969 18.636719 14.550781 18.636719l142.679688.011719c12.515624 0 22.695312 10.183593 22.695312 22.699218 0 12.519532-10.183594 22.703125-22.695312 22.703125h-21.425782c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15h6.394532c12.515624 0 22.695312 10.183594 22.695312 22.699219s-10.183594 22.703125-22.695312 22.703125h-22.429688c-8.285156 0-15 6.714844-15 15 0 8.28125 6.714844 15 15 15h7.398438c12.515624 0 22.695312 10.183594 22.695312 22.699219s-10.179688 22.699218-22.695312 22.699218h-22.429688c-8.285156 0-15 6.71875-15 15 0 8.285157 6.714844 15 15 15h7.398438c12.515624 0 22.695312 10.1875 22.695312 22.703126 0 12.515624-10.179688 22.703124-22.695312 22.703124zm0 0"/>
    </svg>
}











