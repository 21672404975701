import React, {useCallback} from "react";
import NavBar from '../../ui/NavBar'
import {Page, Main, FixedBar, ListWrap, ImgItem, Book} from './style'
import {useNavigate, useParams} from 'react-router-dom'
import TabsView from "../../ui/Tabs";
import SafeAreaView from "../../ui/SafeAreaView";
import dataSource from '../../../data/ids'
import Image from "../../ui/Image";

export default function () {
    const navigate = useNavigate()
    const {id} = useParams()
    const onBook = useCallback(() => {
        navigate('/book')
    }, [])

    const renderImages = useCallback(() => {
        let data = dataSource[id]
        return data.map((src, index) => {
            return <Image style={{marginTop: 2, width: 'inherit', height: 'inherit', overflow: 'visible'}} key={index} src={src} />
        })
    }, [id, dataSource])

    return <SafeAreaView>
        <NavBar/>
        <Main>
            <ListWrap>
                {renderImages()}
            </ListWrap>
            <FixedBar>
                <span>想拍同款请点击按钮</span>
                <Book onClick={onBook}>预约</Book>
            </FixedBar>
        </Main>
    </SafeAreaView>
}