import React, {useCallback, useMemo, useEffect, useRef, useState} from 'react'

export default function ({index = 0, swipeable = true, children, animated, lazyLoad, id, onChangeIndex, onSwitching, style}) {
    const startTouches = useRef(null)
    const startTime = useRef(null)
    const offset = useRef({x: 0, y: 0})
    const wrapRef = useRef(null)
    const velocity = useRef(null)
    const distance = useRef(null)
    const isTouched = useRef(false)
    const _index = useRef(index)
    const [activeIndex, setIndex] = useState(index)
    const isSwiping = useRef(false)
    const rootNode = useRef(null)
    const tabCache = useRef({})
    const direction = useRef(null)
    const isLeft = useRef(null)
    const prevTouches = useRef({x: 0, y: 0})

    const onTouchStart = useCallback((e) => {
        const touches = e.touches
        const startX = touches[0].clientX
        const startY = touches[0].clientY
        if(startX <= 50 || startX >= window.screen.width - 50) {
            isTouched.current = false
            return
        }
        if (touches.length === 1) {
            isTouched.current = true
            isSwiping.current = false
            startTouches.current = {
                x: startX,
                y: startY
            }
            direction.current = null
            prevTouches.current = startTouches.current
            startTime.current = Date.now()
        }
    }, [])

    const shouldTriggerSwipe = useCallback((delta, velocity) => {
        return Math.abs(delta) >= 10 && Math.abs(velocity) > 0.3
    }, [])

    const cancelEvent = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const setTransform = useCallback((value, isAnimated) => {
        if (wrapRef.current) {
            if (isAnimated) {
                wrapRef.current.style.setProperty('transition', 'transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1)')
            } else {
                wrapRef.current.style.setProperty('transition', 'none')
            }
            wrapRef.current.style.setProperty('transform', `translate3d(${-value}%, 0, 0)`)
        }
    }, [])

    const onTouchEnd = useCallback((e) => {
        if(!isTouched.current) return
        // 判断是否为swipe
        const touches = e.changedTouches
        const diffX = touches[0].clientX - startTouches.current.x
        const diffY = touches[0].clientY - startTouches.current.y
        const time = Date.now()
        const timeDiff = time - startTime.current
        isLeft.current = diffX < 0 ? 'left' : 'right'
        distance.current = Math.sqrt(diffX * diffX + diffY * diffY)
        velocity.current = distance.current / timeDiff
        if (isSwiping.current && direction.current === 'h' && swipeable) {
            e.stopPropagation()
            _index.current = activeIndex
            if (shouldTriggerSwipe(distance.current, velocity.current)) {
                if (diffX < 0) {
                    _index.current += 1
                } else {
                    _index.current -= 1
                }
                if (_index.current < 0) {
                    _index.current = 0
                }
                if (_index.current > children.length - 1) {
                    _index.current = children.length - 1
                }
                if (typeof onChangeIndex === 'function') {
                    onChangeIndex(_index.current)
                }
            }
            let value = _index.current * 100
            offset.current.x = value
            setTransform(value, true)
            if (typeof onSwitching === 'function') {
                onSwitching('end', _index, Math.abs(value), isLeft.current)
            }
        }
    }, [activeIndex, children, shouldTriggerSwipe, onChangeIndex, setTransform, swipeable])

    const renderContent = useMemo(() => {
        let subElemens = {}
        React.Children.forEach(children, (child, index) => {
            let newChild = React.cloneElement(child, {
                tabIndex: index,
                ...child.props
            })
            if (newChild.key) {
                subElemens[newChild.key] = newChild
            }
            subElemens[index] = newChild
        })
        if (lazyLoad) {
            return React.Children.map(children, (c, index) => {
                if (activeIndex - 0 <= index && index <= activeIndex + 0) {
                    tabCache.current[index] = subElemens[c.key] || subElemens[index]
                }
                return <div  className={'tab-panel'} data-swipeable="true">
                    {tabCache.current[index]}
                </div>
            })
        } else {
            return React.Children.map(children, (c, index) => {
                return <div  className={'tab-panel'} data-swipeable="true">
                    {React.cloneElement(c, {
                        tabIndex: index,
                        ...c.props
                    })}
                </div>
            })
        }
    }, [children, lazyLoad, activeIndex])

    const stopMoving = useCallback((element) => {
        while (element) {
            let className = element.className || ''
            if (typeof className === 'string') {
                let classList = className.split(' ')
                let stop = classList.indexOf('stopPropagation')
                if (stop < 0) {
                    element = element.parentNode;
                } else {
                    return true
                }
            } else {
                element = element.parentNode;
            }
        }
        return false;
    }, [])

    const onTouchMove = useCallback((e) => {
        if(!isTouched.current) return
        if (stopMoving(e.target)) return
        const touches = e.touches
        // 计算偏移距离
        if (isSwiping.current === false) {
            const diffX = touches[0].clientX - startTouches.current.x
            const diffY = touches[0].clientY - startTouches.current.y
            const diff = Math.abs(diffX) - Math.abs(diffY)
            if (Math.abs(diff) >= 10) {
                if(!direction.current) {
                    direction.current = diff > 0 ? 'h' : 'v'
                }
                isSwiping.current = true
                if(direction.current === 'h') {
                    cancelEvent(e)

                }
                startTouches.current = {
                    x: touches[0].clientX,
                    y: touches[0].clientY
                }
                isLeft.current = diffX < 0 ? 'left' : 'right'
                prevTouches.current = startTouches.current
            }
        }
        if(isSwiping.current === false) return
        if(direction.current === 'h' && swipeable) {
            cancelEvent(e)
            const detaX = ((startTouches.current.x - touches[0].clientX) / 414  * 100)
            const offsetX = offset.current.x + detaX
            if (typeof onSwitching === 'function') {
                onSwitching('move', activeIndex, Math.abs(detaX), isLeft.current)
            }
            setTransform(offsetX)
        }
    }, [setTransform, stopMoving, cancelEvent, swipeable])

    const onTransitionEnd = useCallback(() => {
        setIndex(_index.current)
    }, [])

    useEffect(() => {
        let node = rootNode.current
        node.addEventListener('touchmove', onTouchMove)
        return function remove() {
            node.removeEventListener('touchmove', onTouchMove)
        }
    }, [onTouchMove])

    useEffect(() => {
        let value = index * 100
        offset.current.x = value
        setTransform(value, animated)
        _index.current = index
        setIndex(index)
    }, [index, setTransform, animated])

    return <main
        ref={rootNode}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        className={'tab-panel-wrap'}
        style={{
            flexDirection: 'column',
            ...style
        }}
    >
        <div
            id={id}
            onTransitionEnd={onTransitionEnd}
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                flexShrink: 1,
                height: '100%',
                width: '100%',
                alignItems: 'stretch',
            }} ref={wrapRef}>
            {renderContent}
        </div>
    </main>
}
