import React, {Component} from 'react';
import newyearShortVideo from './newyearShortVideo.png'


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <img src={newyearShortVideo} className={`svg-icon ${className || ""}`} style={{width}}/>
    // return <svg
    //     width={128}
    //     style={style}
    //     height={128}
    //     viewBox={'0 0 128 128'}
    //     xmlns="http://www.w3.org/2000/svg"
    //     className={`svg-icon ${className || ""}`}
    //     xmlnsXlink="http://www.w3.org/1999/xlink"
    // >
    //     <g transform="translate(0, -300)">
    //         <linearGradient id="shortVideo-linear-gradient" gradientUnits="userSpaceOnUse" x1="57.22" x2="57.22" y1="14.25" y2="-14.857">
    //             <stop offset="0" stopColor="#683051"/>
    //             <stop offset="1" stopColor="#40d3a4"/>
    //         </linearGradient>
    //         <linearGradient id="shortVideo-linear-gradient-2" gradientUnits="userSpaceOnUse" x1="69.613" x2="53.488" y1="101.677"
    //                         y2="117.302">
    //             <stop offset="0" stopColor="#ff3345"/>
    //             <stop offset="1" stopColor="#ff9c7a"/>
    //         </linearGradient>
    //         <linearGradient id="shortVideo-linear-gradient-3" x1="50.254" x2="43.004" xlinkHref="#shortVideo-linear-gradient-2" y1="62.818"
    //                         y2="81.318"/>
    //         <linearGradient id="shortVideo-linear-gradient-4" x1="81.774" x2="81.774" xlinkHref="#shortVideo-linear-gradient-2" y1="102.5"
    //                         y2="-9.037"/>
    //         <linearGradient id="shortVideo-linear-gradient-5" gradientUnits="userSpaceOnUse" x1="44.941" x2="37.191" y1="29.204"
    //                         y2="15.954">
    //             <stop offset="0" stopColor="#f1ddb5"/>
    //             <stop offset="1" stopColor="#ffcd9f"/>
    //         </linearGradient>
    //         <linearGradient id="shortVideo-linear-gradient-6" x1="17.107" x2="97.107" xlinkHref="#shortVideo-linear-gradient-2" y1="10.143"
    //                         y2="104.143"/>
    //         <linearGradient id="shortVideo-linear-gradient-7" x1="34.587" x2="41.754" xlinkHref="#shortVideo-linear-gradient-2" y1="-6.172"
    //                         y2="39.161"/>
    //         <linearGradient id="shortVideo-linear-gradient-8" x1="54.475" x2="63.1" xlinkHref="#shortVideo-linear-gradient-2" y1="33.087"
    //                         y2="50.587"/>
    //         <linearGradient id="shortVideo-linear-gradient-9" x1="85.395" x2="79.645" xlinkHref="#shortVideo-linear-gradient-2" y1="81.946"
    //                         y2="104.196"/>
    //         <linearGradient id="shortVideo-linear-gradient-10" x1="70.561" x2="73.144" xlinkHref="#shortVideo-linear-gradient-2" y1="75.044"
    //                         y2="87.294"/>
    //         <linearGradient id="shortVideo-linear-gradient-11" x1="77.025" x2="84.65" xlinkHref="#shortVideo-linear-gradient-2" y1="57.82"
    //                         y2="76.007"/>
    //         <linearGradient id="shortVideo-linear-gradient-12" x1="87.724" x2="87.412" xlinkHref="#shortVideo-linear-gradient-2" y1="70.774"
    //                         y2="87.586"/>
    //         <linearGradient id="shortVideo-linear-gradient-13" x1="76.316" x2="87.899" xlinkHref="#shortVideo-linear-gradient-2" y1="37.868"
    //                         y2="59.702"/>
    //         <linearGradient id="shortVideo-linear-gradient-14" x1="58.331" x2="70.894" xlinkHref="#shortVideo-linear-gradient-2" y1="55.4"
    //                         y2="77.4"/>
    //         <linearGradient id="shortVideo-linear-gradient-15" gradientUnits="userSpaceOnUse" x1="57.31" x2="65.935" y1="122.896"
    //                         y2="105.521">
    //             <stop offset="0" stopColor="#ffe781"/>
    //             <stop offset=".604" stopColor="#ffbb6f"/>
    //             <stop offset="1" stopColor="#ffa265"/>
    //         </linearGradient>
    //         <linearGradient id="shortVideo-linear-gradient-16" x1="60.168" x2="60.168" xlinkHref="#shortVideo-linear-gradient" y1="47" y2="-10.905"/>
    //         <linearGradient id="shortVideo-linear-gradient-17" x1="34.468" x2="38.406" xlinkHref="#shortVideo-linear-gradient-15" y1="56.7"
    //                         y2="46.825"/>
    //         <linearGradient id="shortVideo-linear-gradient-18" x1="48.211" x2="56.836" xlinkHref="#shortVideo-linear-gradient-15" y1="33.368"
    //                         y2="43.368"/>
    //         <path
    //             d="m32.08 20.9a19.92 19.92 0 0 1 .188-8.374 13.905 13.905 0 0 1 4.5-7.259 16.048 16.048 0 0 1 7.694-3.455 22.56 22.56 0 0 1 8.242.214 27.3 27.3 0 0 1 3.928 1.104c.645.212 1.264.5 1.89.744.577.248 1.157.49 1.745.709a30.045 30.045 0 0 0 7.288 1.754 22.609 22.609 0 0 0 7.41-.352 14.231 14.231 0 0 0 3.414-1.235 7.178 7.178 0 0 0 2.607-2.181v-.006a.942.942 0 0 1 1.577 1.037 9.051 9.051 0 0 1 -3.236 2.883 16.223 16.223 0 0 1 -3.874 1.51 24.742 24.742 0 0 1 -8.11.583 32.373 32.373 0 0 1 -7.9-1.707c-.638-.22-1.266-.464-1.891-.716-.589-.22-1.156-.476-1.752-.66a24.548 24.548 0 0 0 -3.591-.958 19.954 19.954 0 0 0 -7.285-.1 13.348 13.348 0 0 0 -6.35 2.936 11.056 11.056 0 0 0 -3.5 5.859 17.026 17.026 0 0 0 -.039 7.124v.014a1.5 1.5 0 0 1 -2.95.549z"
    //             fill="url(#shortVideo-linear-gradient)"/>
    //         <path
    //             d="m69.427 103.562c-.888-.385-1.767-.783-2.631-1.21a3.716 3.716 0 0 0 -4.492.941q-.468.556-.989 1.074s-7.9-3.29-14.177 1.6a3.5 3.5 0 0 0 -.8 4.68 10.5 10.5 0 0 0 9.783 4.952s-2.47 5.281.247 10.149a3.361 3.361 0 0 0 4.882 1.171c7.54-4.937 7.21-14.2 7.046-15.692q.891-.544 1.736-1.106a3.751 3.751 0 0 0 -.605-6.559z"
    //             fill="url(#shortVideo-linear-gradient-2)"/>
    //         <path d="m41.253 63.214c4.657 4.327 4.6 14.233 4.108 18.945a1.746 1.746 0 0 0 2.741 1.6 15 15 0 0 0 6.353-10.271z"
    //               fill="url(#shortVideo-linear-gradient-3)"/>
    //         <path
    //             d="m97.259 40.836c-9.221-4.2-18.2-17.734-32.389-17.18l25.124 56.151c9.145-12.398 7.126-26.407 2.706-31.913-.526-.657 3.068-2.207 4.325-2.614a2.38 2.38 0 0 0 .234-4.444z"
    //             fill="url(#shortVideo-linear-gradient-4)"/>
    //         <path
    //             d="m68.3 111.224-4.391-.321a2.618 2.618 0 0 1 -2.432-2.508l-.171-4.031c7.684-7.609 6.852-21.718-8.971-32.307-9.2-6.151-15.775-12.823-20.042-19.3-4.283-6.497-6.623-11.987-6.902-16.781a7.869 7.869 0 0 0 -1.684-3.953c-1.816-2.133-1.105-3.93.452-4.894a3.976 3.976 0 0 0 1.982-3.3c.028-3.008 1.329-5.233 3.765-5.732 1.331-.273 1.553.326 3.383.814a7.1 7.1 0 0 0 3 .194c37.398-6.355 53.911 31.511 54.556 48.154.425 10.911-1.685 31.219-22.545 43.965z"
    //             fill="url(#shortVideo-linear-gradient-5)"/>
    //         <path
    //             d="m68.3 111.224-4.391-.321a2.618 2.618 0 0 1 -2.432-2.508l-.171-4.031c7.684-7.609 6.852-21.718-8.971-32.307a72.912 72.912 0 0 1 -18.78-17.478c3.066.531 4.249-1.344 4.683-3.226a4.408 4.408 0 0 1 3.822-3.393c13.112-1.374 21.254-15.861 18.6-26.569 21.4 9.33 29.7 33.1 30.189 45.868.421 10.911-1.689 31.219-22.549 43.965z"
    //             fill="url(#shortVideo-linear-gradient-6)"/>
    //         <circle cx="39.636" cy="25.765" fill="url(#shortVideo-linear-gradient-7)" r="2.125"/>
    //         <path
    //             d="m50.187 45.5a23.008 23.008 0 0 0 9.52-12.145c5.284 2.891 1.883 8.122 11.472 13.462a1.852 1.852 0 0 1 0 3.384c-3.159 1.817-14.179 2.232-20.992-4.701z"
    //             fill="url(#shortVideo-linear-gradient-8)"/>
    //         <path
    //             d="m82.772 97.313c-.115 0-.231 0-.348-.014a5.493 5.493 0 0 1 -3.733-2.027 1.5 1.5 0 0 1 2.289-1.939 2.568 2.568 0 0 0 1.676.975.916.916 0 0 0 .678-.163 1.5 1.5 0 1 1 1.966 2.269 3.821 3.821 0 0 1 -2.528.899z"
    //             fill="url(#shortVideo-linear-gradient-9)"/>
    //         <path
    //             d="m70.777 82.46a6.543 6.543 0 0 1 -1.679-.224 1.5 1.5 0 1 1 .763-2.9 2.48 2.48 0 0 0 2.727-.631 1.5 1.5 0 1 1 2.441 1.742 5.209 5.209 0 0 1 -4.252 2.013z"
    //             fill="url(#shortVideo-linear-gradient-10)"/>
    //         <path
    //             d="m79.562 69.176a6.594 6.594 0 0 1 -2.362-.461 1.5 1.5 0 0 1 1.082-2.8 2.627 2.627 0 0 0 2.468-.087 1.824 1.824 0 0 0 .7-1.619 1.5 1.5 0 1 1 3-.1 4.792 4.792 0 0 1 -1.965 4.164 5 5 0 0 1 -2.923.903z"
    //             fill="url(#shortVideo-linear-gradient-11)"/>
    //         <path
    //             d="m87.082 82.788a5.978 5.978 0 0 1 -2.639-.633 1.5 1.5 0 1 1 1.313-2.7 2.522 2.522 0 0 0 2.848-.215 1.5 1.5 0 0 1 2.823 1.009 3.725 3.725 0 0 1 -2.163 2.089 5.541 5.541 0 0 1 -2.182.45z"
    //             fill="url(#shortVideo-linear-gradient-12)"/>
    //         <path
    //             d="m78.9 51.38a4.856 4.856 0 0 1 -1.01-.1 1.5 1.5 0 1 1 .617-2.935 3.58 3.58 0 0 0 3.122-1.355 2.913 2.913 0 0 0 .849-2.754 1.5 1.5 0 1 1 2.847-.943 5.838 5.838 0 0 1 -1.459 5.7 6.868 6.868 0 0 1 -4.966 2.387z"
    //             fill="url(#shortVideo-linear-gradient-13)"/>
    //         <path
    //             d="m60.765 66.082a4.385 4.385 0 0 1 -1.911-.416 1.5 1.5 0 1 1 1.3-2.7 2.476 2.476 0 0 0 2.276-.46 2.4 2.4 0 0 0 1.121-2.036 1.5 1.5 0 1 1 2.982-.328 5.232 5.232 0 0 1 -2.372 4.813 5.974 5.974 0 0 1 -3.396 1.127z"
    //             fill="url(#shortVideo-linear-gradient-14)"/>
    //         <path
    //             d="m68.306 112.725c-.037 0-.075 0-.112 0l-4.394-.325a4.121 4.121 0 0 1 -3.823-3.94l-.169-4.031a1.5 1.5 0 1 1 3-.127l.169 4.031a1.122 1.122 0 0 0 1.041 1.075l4.393.323a1.5 1.5 0 0 1 -.107 3z"
    //             fill="url(#shortVideo-linear-gradient-15)"/>
    //         <path
    //             d="m30.767 20.88a16.226 16.226 0 0 1 2.333-8.793 10.273 10.273 0 0 1 3.467-3.315 10.97 10.97 0 0 1 4.533-1.412 16.321 16.321 0 0 1 8.79 1.955 19.883 19.883 0 0 1 1.922 1.113c.612.383 1.247.865 1.76 1.227a29.446 29.446 0 0 0 3.453 2.057 51.65 51.65 0 0 0 15.611 4.788 39.058 39.058 0 0 0 8.142.381 19.268 19.268 0 0 0 3.922-.654 7.846 7.846 0 0 0 3.261-1.727l.011-.011a.941.941 0 0 1 1.319 1.343 9.674 9.674 0 0 1 -4.03 2.254 21.22 21.22 0 0 1 -4.323.8 41.091 41.091 0 0 1 -8.588-.264 53.869 53.869 0 0 1 -16.4-4.759 31.83 31.83 0 0 1 -3.778-2.175c-.635-.436-1.121-.793-1.7-1.143a17.592 17.592 0 0 0 -1.723-.956 13.746 13.746 0 0 0 -7.387-1.544 7.688 7.688 0 0 0 -5.876 3.592 13.383 13.383 0 0 0 -1.719 7.247v.016a1.5 1.5 0 0 1 -3 .012z"
    //             fill="url(#shortVideo-linear-gradient-16)"/>
    //         <path
    //             d="m34.549 56.212a7.277 7.277 0 0 1 -1.249-.112 1.5 1.5 0 1 1 .512-2.955c1.448.248 1.973-.251 2.146-.414 1.031-.981 1.1-3.457.973-4.5a1.5 1.5 0 1 1 2.978-.365c.059.481.506 4.767-1.884 7.04a4.874 4.874 0 0 1 -3.476 1.306z"
    //             fill="url(#shortVideo-linear-gradient-17)"/>
    //         <path
    //             d="m40.062 49.63q-.874 0-1.762-.07a1.5 1.5 0 0 1 .238-2.991 18.433 18.433 0 0 0 15.794-6.817c4.322-5.1 6.208-12.047 4.8-17.709a1.462 1.462 0 0 1 -.044-.361 1.5 1.5 0 0 1 2.975-.283c1.593 6.553-.54 14.509-5.447 20.293a21.427 21.427 0 0 1 -16.554 7.938z"
    //             fill="url(#shortVideo-linear-gradient-18)"/>
    //     </g>
    // </svg>
}







