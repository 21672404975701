import {useCallback, useState} from 'react'
import NavBar from "../../ui/NavBar";
import React from "react";
import TabsView from "../../ui/Tabs";
import {TabBar, Tab, ListWrap} from './style'
import AppTabBar from "../../ui/TabBar";
import SafeAreaView from "../../ui/SafeAreaView";

export default function () {
    const [activeIndex, setIndex] = useState(0)
    const tabs = [
        {
            title: '全部'
        }, {
            title: '未付款'
        },
        {
            title: '进行中'
        },
        {
            title: '已完成'
        },
        {
            title: '已取消'
        }
    ]

    const onTab = useCallback((index) => {
        setIndex(index)
    }, [])

    const renderTabBar = useCallback(({tabs, activeTab}) => {
        return <TabBar>
            {tabs.map((item, index) => {
                return <Tab key={index} onClick={() => onTab(index)} active={index === activeIndex}>{item.title}</Tab>
            })}
        </TabBar>
    }, [activeIndex])

    const renderTabContent = useCallback((item, index) => {
        return <ListWrap key={index}>暂无数据</ListWrap>
    }, [])

    return <SafeAreaView>
        <NavBar title={'订单列表'}/>
        <TabsView
            tabBarBackgroundColor={'transparent'}
            tabs={tabs}
            onChange={(tab) => {
                // lastScrollTop = 0
                // tab.setAlreadyActiveIsTrue()
                // routerContext.replaceCurrentParams({
                //     channelId: tab.id
                // })
            }}
            notify={true}
            active={0}
            initIndex={0}
            renderTabBar={renderTabBar}>
            {tabs.map((item, index) => {
                return renderTabContent(item, index)
            })}
        </TabsView>
    </SafeAreaView>
}