import React, {useCallback, useMemo, useRef, useState, useEffect} from 'react'

const computeOffsetValue = (index, infinite) => {
    if(infinite) {
        return -(index * 100) - 100
    } else {
        return -(index * 100)
    }
}

export default (props) => {
    const {
        dataSource = [1, 2, 3, 4],
        renderItem,
        renderDots,
        name,
        className,
        style,
        swipe = true,
        dots = true,
        infinite = false,
        autoplaySpeed = 3000,
        initialSlide = 0,
        afterChange,
        autoplay = false,
        edgeSwipeThreshold = 50
    } = props
    const index = useRef(initialSlide)
    const lastIndex = useRef(initialSlide)
    const slideTrack = useRef(null)
    const rootNode = useRef(null)
    const startPoint = useRef({x: 0, y: 0})
    const curPoint = useRef({x: 0, y: 0})
    const offsetLeft = useRef(computeOffsetValue(initialSlide, infinite))
    const startTime = useRef(null)
    const [items, setItems] = useState([])
    const transitionEnd = useRef(false)
    const animatTimeStart = useRef(null)
    const requestId = useRef(null)
    const userAction = useRef(null)
    const direction = useRef(null)
    const isSwiping = useRef(false)
    const isTouchEdge = useRef(false)
    const isOnEdgeIndex = useRef(false)
    const isTransitionCancel = useRef(true)

    const translate = useCallback(() => {
        if (slideTrack.current) {
            transitionEnd.current = true
            offsetLeft.current = computeOffsetValue(index.current, infinite)
            slideTrack.current.style.transition = 'transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)'
            slideTrack.current.style.transform = `translate3d(${offsetLeft.current}%, 0, 0)`
        }
    }, [infinite])

    const onPrev = useCallback((isClick) => {
        if(isClick && transitionEnd.current) return
        if(isClick) {
            userAction.current = 'click'
        }
        cancelAnimationFrame(requestId.current)
        animatTimeStart.current = null
        lastIndex.current = index.current
        index.current -= 1
        if(infinite === false && index.current < 0) {
            index.current = 0
        } else {
            translate()
        }
    }, [infinite])

    const onNext = useCallback((isClick) => {
        if(isClick && transitionEnd.current) return
        if(isClick) {
            userAction.current = 'click'
        }
        cancelAnimationFrame(requestId.current)
        animatTimeStart.current = null
        lastIndex.current = index.current
        index.current += 1
        if(infinite == false && index.current > dataSource.length - 1) {
            index.current = dataSource.length - 1
        } else {
            translate()
        }
        // if (!transitionEnd.current) {
        //     userAction.current = 'click'
        //     cancelAnimationFrame(requestId.current)
        //     // clearInterval(requestId.current)
        //     animatTimeStart.current = null
        //     lastIndex.current = index.current
        //     index.current += 1
        //     if(infinite == false && index.current > dataSource.length - 1) {
        //         index.current = dataSource.length - 1
        //     } else {
        //         translate()
        //     }
        // }
    }, [dataSource.length, infinite])

    const onTouchStart = useCallback((e) => {
        const touches = e.touches
        if (touches.length === 1) {
            const startX = touches[0].clientX
            const startY = touches[0].clientY
            if(startX < edgeSwipeThreshold || startX > window.screen.width - edgeSwipeThreshold) {
                isTouchEdge.current = true
            } else {
                startTime.current = Date.now()
                isOnEdgeIndex.current = false
                isTouchEdge.current = false
                direction.current = null
                isSwiping.current = false
                startPoint.current = {
                    x: startX,
                    y: startY
                }
                curPoint.current = {...startPoint.current}
            }
        }
    }, [])

    const onTouchMove = useCallback((e) => {
        const touches = e.touches
        if (touches.length === 1 && isTouchEdge.current === false) {
            e.preventDefault()
            cancelAnimationFrame(requestId.current)
            animatTimeStart.current = null
            const diffX = touches[0].clientX - curPoint.current.x
            const diffY = touches[0].clientY - curPoint.current.y
            const diff = Math.abs(diffX) - Math.abs(diffY)
            if(Math.abs(diff) >= 10) {
                if(direction.current === null) {
                    direction.current = diff > 0 ? 'h' : 'v'
                }
            }
            if(isOnEdgeIndex.current) return
            if(isSwiping.current === false) {
                if(direction.current) {
                    isSwiping.current = true
                    userAction.current = 'move'
                    e.preventDefault()
                    if(direction.current === 'h') {
                        e.stopPropagation()
                    }
                    curPoint.current.x = touches[0].clientX
                    curPoint.current.y = touches[0].clientY
                }
            } else {
                e.preventDefault()
                if(direction.current === 'h' && swipe) {
                    offsetLeft.current += (diffX / 414) * 100
                    curPoint.current.x = touches[0].clientX
                    const maxOffset = -(dataSource.length - 1) * 100
                    if(infinite === false && offsetLeft.current > 0) {
                        offsetLeft.current = 0
                        isOnEdgeIndex.current = true
                    } else if (infinite === false && offsetLeft.current < maxOffset) {
                        offsetLeft.current = maxOffset
                        isOnEdgeIndex.current = true
                    } else {
                        e.stopPropagation()
                        if (slideTrack.current) {
                            slideTrack.current.style.transition = 'none'
                            slideTrack.current.style.transform = `translate3d(${(offsetLeft.current)}%, 0, 0)`
                        }
                    }
                }
            }
        }
    }, [swipe, dataSource.length, infinite])

    const onTouchEnd = useCallback((e) => {
        const touches = e.changedTouches
        if (touches.length === 1 && isTouchEdge.current === false) {
            if (userAction.current === 'move') {
                userAction.current = 'end'
            }
            const touche = touches[0]
            const diffX = touche.clientX - startPoint.current.x
            const diffY = touche.clientY - startPoint.current.y
            const timeDiff = Date.now() - startTime.current
            const distance = Math.sqrt(diffX * diffX + diffY * diffY)
            const velocity = distance / timeDiff
            if(isSwiping.current && swipe && !isOnEdgeIndex.current && direction.current === 'h') {
                // 防止嵌套的时候冒泡到父元事件从而改变父元素index
                e.stopPropagation()
                if (Math.abs(distance) >= 10 && Math.abs(velocity) > 0.3) {
                    if (diffX > 0) {
                        onPrev()
                    } else {
                        onNext()
                    }
                } else if (Math.abs(distance) >= 414 / 3) {
                    if (diffX > 0) {
                        onPrev()
                    } else {
                        onNext()
                    }
                }
                translate()
            }
        }
    }, [swipe])


    const onTransitionEnd = useCallback(() => {
        transitionEnd.current = false
        isTransitionCancel.current = false
        const length = dataSource.length
        const maxIndex = dataSource.length - 1
        if (index.current > maxIndex) {
            index.current = 0
            offsetLeft.current = infinite ? -100 : 0
            slideTrack.current.style.transition = 'none 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)'
            slideTrack.current.style.transform = `translate3d(${offsetLeft.current}%, 0, 0)`
        }
        if (index.current < 0) {
            index.current = maxIndex
            offsetLeft.current = -(length * 100)
            slideTrack.current.style.transition = 'none 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)'
            slideTrack.current.style.transform = `translate3d(${offsetLeft.current}%, 0, 0)`
        }
        if (userAction.current === 'click' || userAction.current === 'end') {
            userAction.current = null
            if(autoplay) {
                requestId.current = requestAnimationFrame(autoLoop)
            }
        }
        if(lastIndex.current !== index.current && afterChange) {
            afterChange(index.current)
        }
    }, [dataSource.length, autoplay])

    useEffect(() => {
        if (rootNode.current) {
            rootNode.current.addEventListener('touchmove', onTouchMove)

        }
        return () => {
            if(rootNode.current) {
                rootNode.current.removeEventListener('touchmove', onTouchMove)
            }
        }
    }, [])

    const autoLoop = useCallback((timestamp) => {
        if (!animatTimeStart.current) animatTimeStart.current = timestamp
        const progress = timestamp - animatTimeStart.current
        if (progress <= autoplaySpeed) {
            requestId.current = requestAnimationFrame(autoLoop)
        } else {
            animatTimeStart.current = timestamp
            const maxOffset = computeOffsetValue(dataSource.length, infinite)
            if(offsetLeft.current <= maxOffset) {
                index.current = 0
                offsetLeft.current = infinite ? -100 : 0
                slideTrack.current.style.transition = 'none 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)'
                slideTrack.current.style.transform = `translate3d(${offsetLeft.current}%, 0, 0)`
            }
            onNext()
            requestId.current = requestAnimationFrame(autoLoop)
        }
    }, [autoplaySpeed, dataSource.length, infinite])

    useEffect(() => {
        // requestId.current = setInterval(onNext, 2000)
        if (autoplay) {
            requestId.current = requestAnimationFrame(autoLoop)
        }
        return () => {
            // clearInterval(requestId.current)
            cancelAnimationFrame(requestId.current)
        }
    }, [autoplay, autoLoop])

    useEffect(() => {
        let firstItem = null
        let lastItem = null
        let _items = []
        dataSource.forEach((item, index) => {
            _items.push(item)
            if (index === 0) {
                firstItem = item
            }
            if (index === dataSource.length - 1) {
                lastItem = item
            }
        })
        if(infinite && _items.length) {
            _items.push(firstItem)
            _items.unshift(lastItem)
        }
        setItems(_items.slice(0))
    }, [dataSource.length, infinite])

    const renderContent = useMemo(() => {
        return items.map((item, index) => {
            if(renderItem) {
                return renderItem({item, index})
            }
            return <div key={index} style={{
                width: '100%',
                flexShrink: 0,
            }}>{item}</div>
        })
    }, [items.length, renderItem])

    const renderPaging = useMemo(() => {
        if(renderDots) {
            return renderDots(dataSource)
        }
    }, [renderDots, dataSource.length])

    return <div className={className}>
        <div
            data-name={name}
            ref={rootNode}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            style={{
                overflow: 'hidden',
                height: '100%',
                ...style
            }}>
            <div
                onTransitionEnd={onTransitionEnd} ref={slideTrack} style={{
                height: '100%',
                display: 'flex',
                direction: 'ltr',
                transform: `translate3d(${offsetLeft.current}%, 0, 0)`
            }}>
                {renderContent}
            </div>
        </div>
        {renderPaging}
        {/*<button onClick={onPrev}>上一张</button>*/}
        {/*<button onClick={onNext}>下一张</button>*/}
    </div>
}
