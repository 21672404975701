import styled from "@xstyled/styled-components";

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  img{
    max-width: 100%;
    //width: 100%;
    //height: 100%;
  }
  // background-image: url(${(p) => p.src});
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
`