import styled from "@xstyled/styled-components";

export const Form = styled.form`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
`

export const Name = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
`

export const FormItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
  select {
    flex: 1;
    height: 30px;
    border: 1px solid #ccc;
    option {
      
    }
  }
  label {
    width: 80px;
    font-size: 14px;
  }
  input {
    height: 100%;
    outline: none;
    flex: 1;
    border: 0px;
    border-bottom: 1px solid #ddd;
  }
`

export const FormDesc = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
  justify-content: flex-end;
  span {
    font-size: 12px;
    color: blue;
    :first-child {
      margin-right: 10px;
    }
  }
`

export const Button = styled.button`
  width: 150px;
  border: 0;
  height: 30px;
  margin-top: 50px;
  border-radius: 5px;
  
`