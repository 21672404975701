import React, {useState, useCallback} from 'react'
import Carouse from '../../ui/Carouse'
import {Page, Main, ItemWrapper, Banner, DotWrap, Dot, ArtWrap, ImgWrap, ArtTitle, Title, ImgMask, ArtBox} from './style'
import NavBar from "../../ui/NavBar";
import Portrait from '../../item/portrait'
import Landscapte from '../../item/landscapte'
import banners from "../../../data/ads";
import arts from "../../../data/arts";


export default function () {
    const [active, setIndex] = useState(0)

    const renderItem = useCallback(({item, index}) => {
        return <Landscapte id={item.id} src={item.src} key={index}/>
    }, [])

    const renderDot = useCallback((item, index) => {
        return <Dot key={index} active={index === active}></Dot>
    }, [active])

    const renderDots = useCallback((dots) => {
        return <DotWrap>
            {dots.map((dot, index) => {
                return renderDot(dot, index)
            })}
        </DotWrap>
    }, [renderDot])

    const renderArts = useCallback(() => {
        return arts.map((item, index) => {
            return <li key={index}>
                <Portrait id={item.id} src={item.src} title={item.title} />
            </li>
        })
    }, [])

    return <Page>
        <NavBar title={'波波映像'} fixed={true} showBack={false} />
        <Main>
            <Carouse
                className={'carousel-view'}
                name={'0'}
                autoplay={false}
                swipe={true}
                dataSource={banners}
                infinite={true}
                renderItem={renderItem}
                renderDots={renderDots}
                afterChange={index => setIndex(index)}/>
            <div>
                <ArtTitle>作品欣赏</ArtTitle>
                <div>
                    <ArtWrap>
                        {renderArts()}
                    </ArtWrap>
                </div>
            </div>
        </Main>
    </Page>
}