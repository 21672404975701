import React from 'react'

export default function ({messages, dot, style = {}}) {
    if (dot) {
        return <div style={{

            backgroundColor: '#FF338B',
            position: 'absolute',
            right: -26,
            top: -2,
            fontSize: 12,
            transform: 'scale(0.8)',
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 3,
            paddingBottom: 3,
            width: 44,
            borderRadius: 12,
            color: 'white',
            boxSizing: 'border-box',
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'center'
        }}>送VIP</div>
    }

}
