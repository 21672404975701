import React, {useRef, useCallback, useEffect, useState} from 'react'
import './App.css';
import AppTabBar, {Item} from './component/ui/TabBar'
import SafeAreaView from './component/ui/SafeAreaView'
import Icon from './component/svgIcon'
import {ThemeProvider} from '@xstyled/styled-components'
import ThemeConfig from './themeConfig'
import Views from './component/views'
import {useNavigate, useParams} from 'react-router-dom'
import back from "./component/svgIcon/icons/back";
const map = {
    'index': 0,
    'product': 1,
    'user': 2
}

function App() {
    const {name} = useParams()
    const navigate = useNavigate()
    const [active, setActive] = useState(map[name] || 0)

    useEffect(() => {
        setActive(map[name] || 0)
    }, [name])
    const items = useRef([{
        name: '首页',
        id: 'index',
        view: 'home',
        iconName: 'home',
        selectedIconName: 'homeHighlight',
        // selected: true
    }, {
        name: "作品",
        id: 'product',
        view: 'product',
        iconName: 'rank',
        selectedIconName: 'rankHighlight',
    }, {
        name: "我的",
        id: 'user',
        view: 'user',
        iconName: 'mine',
        selectedIconName: 'mineHighlight',
    }])

    function renderContent(tabItem) {
        const Comp = Views[tabItem.view]
        return <Comp/>
    }

    const renderTabs = (tabItems, routerContext) => {
        return tabItems.filter(n => (!n.envType || (n.envType === process.env.REACT_APP_SMALL_WEB))).map((tabItem, i) => {
            return (
                <Item
                    prerendering={tabItem.name === '直播'}
                    title={tabItem.name}
                    key={tabItem.name}
                    icon={<Icon key={`i1-${i}`} name={tabItem.iconName} width={28} viewBox={'0 0 28 28'}/>}
                    selectedIcon={<Icon key={`i2-${i}`} name={tabItem.selectedIconName} width={28}
                                        viewBox={'0 0 28 28'}/>}
                    onPress={(index, cb) => {
                        // setActive(index)
                        navigate('/'+tabItem.id, { replace: true })
                    }}
                >
                    {renderContent(tabItem)}
                </Item>
            )
        })
    }

    return (
        <ThemeProvider theme={ThemeConfig}>
            <SafeAreaView>
                <AppTabBar
                    initialIndex={active}
                    swipeable={false}
                    prerenderingSiblingsNumber={0}
                    unselectedTintColor="#8085B2"
                    tintColor="#24DFFF"
                    barTintColor="#2C1054">
                    {renderTabs(items.current)}
                </AppTabBar>
            </SafeAreaView>
        </ThemeProvider>
    );
}

export default App;
