import NavBar from "../../ui/NavBar";
import SafeAreaView from "../../ui/SafeAreaView";
import React, {useCallback, useState} from "react";
import {Form, MainPage, FormItem, FormDesc, Button, Name} from './style'
import {useNavigate} from 'react-router-dom'
import Icon from '../../svgIcon'


export default function () {
    const navigate = useNavigate()



    return <SafeAreaView>
        <NavBar title={'会员卡'} />
        <MainPage>
            <Icon name={'vipCard'} />
            你还没开通会员
        </MainPage>
    </SafeAreaView>
}