import React from 'react'

export default function ({messages, dot, style = {}}) {
    if (dot) {
        return <div style={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: '#F9D34D',
            position: 'absolute',
            right: -4,
            top: 0, ...style
        }}></div>
    }
    if (messages) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '50%',
            // right: 0,
            top: 0,
            borderRadius: 8,
            padding: '0 4px',
            boxSizing: 'border-box',
            fontSize: 10,
            minWidth: 16,
            height: 16,
            backgroundColor: '#F9D34D',
            color: 'black',
            transform: 'translate(5px, -5px)',
            ...style,
        }}
        >{messages > 99 ? '99+' : messages}</div>
    } else {
        return null
    }
}
