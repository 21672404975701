import React, {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {Page, UserWrap, UserName, UserImg, List} from './style'
import Icon from "../../svgIcon";


export default function () {
    const navigate = useNavigate()
    const goOrder = useCallback(() => {
        navigate('/order')
    }, [])

    const goCard = useCallback(() => {
        navigate('/card')
    }, [])

    const goLogin = useCallback(() => {
        navigate('/login')
    }, [])


    return <Page>
        <UserWrap>
            <UserImg>
                <Icon name={'userpic'}/>
            </UserImg>
            <UserName onClick={goLogin}>登录</UserName>
        </UserWrap>
        <List>
            <li onClick={goOrder}>我的订单</li>
            {/*<li onClick={goCard}>我的相册</li>*/}
            {/*<li>我的积分</li>*/}
            <li onClick={goCard}>我的会员卡</li>
            {/*<li>我的优惠券</li>*/}
        </List>
    </Page>
}