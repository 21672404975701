import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >

        <defs>
            <filter x="-7.6%" y="-7.8%" width="115.2%" height="115.6%" filterUnits="objectBoundingBox"
                    id="homeHighlight-filter-1">
                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0.854901961   0 0 0 0 0.294117647   0 0 0 0 0.968627451  0 0 0 1 0"
                               type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <linearGradient x1="50%" y1="38.8191061%" x2="45.9286721%" y2="67.0138889%" id="homeHighlight-linearGradient-2">
                <stop stopColor="#24DFFF" stopOpacity="0.0317690122" offset="0%"/>
                <stop stopColor="#2FE3EB" offset="100%"/>
            </linearGradient>
            <linearGradient x1="100%" y1="50%" x2="30.5091986%" y2="50%" id="homeHighlight-linearGradient-3">
                <stop stopColor="#EF82FC" stopOpacity="0.579190341" offset="0%"/>
                <stop stopColor="#FF328B" offset="100%"/>
            </linearGradient>
            <path
                d="M19.1331508,6.42915488 L29.3669605,23.4855042 C29.6511086,23.9590845 29.4975436,24.5733448 29.0239633,24.8574929 C28.8685479,24.9507422 28.6907115,25 28.5094675,25 L8.0418483,25 C7.48956355,25 7.0418483,24.5522847 7.0418483,24 C7.0418483,23.8187561 7.09110613,23.6409196 7.18435537,23.4855042 L17.418165,6.42915488 C17.7023132,5.95557461 18.3165734,5.80200955 18.7901537,6.08615771 C18.9309033,6.17060747 19.0487011,6.28840527 19.1331508,6.42915488 Z"
                id="homeHighlight-path-4"/>
        </defs>
        <g id="icon/tabbar/homeHighlight-highlight" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g filter="url(#homeHighlight-filter-1)" transform="translate(0.000000, -4.000000)">
                <rect id="矩形" fill="#110036" opacity="0" x="0" y="4" width="28" height="28"/>
                <path
                    d="M12.0349145,10.8857994 L21.8606939,22.3492086 C22.220116,22.7685345 22.1715545,23.3998345 21.7522287,23.7592566 C21.5709855,23.9146078 21.3401486,24 21.1014373,24 L1.44987856,24 C0.897593807,24 0.449878557,23.5522847 0.449878557,23 C0.449878557,22.7612887 0.535270718,22.5304517 0.690621955,22.3492086 L10.5164013,10.8857994 C10.8758235,10.4664735 11.5071235,10.417912 11.9264493,10.7773341 C11.9653281,10.8106588 12.0015898,10.8469206 12.0349145,10.8857994 Z"
                    id="三角形" fill="url(#homeHighlight-linearGradient-2)"
                    transform="translate(11.275658, 17.000000) rotate(-294.000000) translate(-11.275658, -17.000000) "></path>
                <g id="三角形备份"
                   transform="translate(18.275658, 15.000000) rotate(-292.000000) translate(-18.275658, -15.000000) ">
                    <use fill="url(#homeHighlight-linearGradient-3)" fillRule="evenodd" xlinkHref="#homeHighlight-path-4"/>
                    <path stroke="#DA4BF7" strokeWidth="0.1"
                          d="M18.7539274,6.12282094 C18.5314612,5.99312065 18.2774541,5.96396399 18.0452491,6.02201526 C17.809382,6.08098202 17.59601,6.22992904 17.4610396,6.45487966 L7.22723002,23.511229 C7.13864324,23.6588737 7.0918483,23.8278183 7.0918483,24 C7.0918483,24.2623353 7.19818067,24.4998353 7.37009686,24.6717514 C7.54201304,24.8436676 7.77951304,24.95 8.0418483,24.95 L28.5094675,24.95 C28.6816493,24.95 28.8505939,24.9032051 28.9982385,24.8146183 C29.2231891,24.6796479 29.3721362,24.4662759 29.4311029,24.2304088 C29.4900697,23.9945418 29.4590562,23.7361797 29.3240858,23.511229 L18.7539274,6.12282094 Z"
                          strokeLinejoin="square"></path>
                </g>
            </g>
        </g>
    </svg>
}





