import React, {useCallback, useMemo, useState, useEffect, useRef} from 'react'
import DefaultTabBar from './DefaultTabBar'
import SwipeableViews from '../SwipeableViews'
import './style.css'

const events = [
    "resize",
    "scroll",
    "pageshow"
]

export default function (props) {
    const {
        tabs = [],
        scrollParent,
        openSticky,
        style,
        animated = true,
        initIndex = 0,
        children,
        offsetTop = 0,
        fixed = true,
        flex = true,
        onChange,
        page = 5,
        swipeable = true,
        renderTabBar,
        tabBarBackgroundColor
    } = props
    const [activeIndex, setIndex] = useState(initIndex)
    const contentWrap = useRef(null)
    const topWrap = useRef(null)
    const [fn, setFn] = useState()
    const elements = useRef([])
    const [isAnimated, setAnimated] = useState(animated)
    const tabRef = useRef(null)
    const tabBarRef = useRef(null)
    const clientTop = useRef(0)
    const [direction, setDirection] = useState('left')
    const [offset, setOffset] = useState(0)
    const isMoving = useRef(false)

    const _onChange = useCallback((index) => {
        const tab = tabs.find((item, i) => i === index)
        setIndex(index)
        setAnimated(true)
        onChange && onChange(tab, index)
    }, [tabs.length])

    const onSwitching = useCallback((type, index, x, isLeft) => {
        isMoving.current = type === 'move' ? true : false
        setDirection(isLeft)
        setOffset(x)
    }, [])

    const handleEvent = useCallback((e) => {
        if (tabRef.current) {
            const statusNode = document.getElementById('_statusBar')
            let _height = 0
            if (statusNode) {
                _height = statusNode.offsetHeight
            }
            const node = tabRef.current
            const {top, bottom} = node.getBoundingClientRect()
            const scrollTop = window.pageYOffset || window.document.documentElement.scrollTop
            // const offsetTop = top + scrollTop - window.document.body.clientTop
            // console.log(offsetTop - 62 < scrollTop)
            const elOffsetTop = clientTop.current - offsetTop - 20
            // console.log(`top=${top},scrollTop=${scrollTop}, elOffsetTop=${elOffsetTop}, clientTop=${clientTop.current}`)
            if (top <= offsetTop + _height) {
                tabBarRef.current.style.setProperty('position', 'fixed')
                if (offsetTop) {
                    tabBarRef.current.style.setProperty('transform', 'translateZ(0)')
                    tabBarRef.current.style.setProperty('top', `calc(${offsetTop + _height}px)`)
                }
            } else {
                tabBarRef.current.style.setProperty('position', 'static')
            }
        }
    }, [offsetTop])

    useEffect(() => {
        if (tabBarRef.current) {
            clientTop.current = tabBarRef.current.getBoundingClientRect().top
        }
        if (openSticky) {
            if (scrollParent) {
                const parent = scrollParent || window
                // console.log(parent)
                events.forEach(event => {
                    parent.addEventListener(event, handleEvent)
                })
                return () => {
                    events.forEach(event => {
                        parent.removeEventListener(event, handleEvent)
                    })
                }
            }
        }
    }, [handleEvent, openSticky, scrollParent])

    useEffect(() => {
        setAnimated(animated)
    }, [animated])

    useEffect(() => {
        const index = tabs[initIndex] ? initIndex : 0
        setIndex(index)
    }, [initIndex, tabs.length])

    const _renderTabBar = useMemo(() => {
        if (renderTabBar) {
            return renderTabBar({
                activeTab: activeIndex,
                tabs: tabs,
                onChange: _onChange,
                page,
                offset,
                direction,
                isMoving: isMoving.current
            })
        } else {
            return <DefaultTabBar isMoving={isMoving.current} direction={direction} offset={offset}
                                  initIndex={activeIndex} onChange={_onChange} tabs={tabs} page={page}/>
        }
    }, [activeIndex, _onChange, tabs, page, renderTabBar, offset, direction])

    return <section style={style} ref={tabRef} className={'tab'}>
        <nav className={'tab-bar'} style={{
            zIndex: 1
        }}>
            <div className={'tab-bar-content'}>
                <div ref={tabBarRef} className={'tab-bar-top'}
                     style={{position: fixed ? null : 'static', display: flex ? 'flex' : 'block', background: tabBarBackgroundColor}}>
                    {_renderTabBar}
                </div>
            </div>
        </nav>
        {/*{props.notify && tabs[activeIndex] && tabs[activeIndex].name !== '楼凤约啪' && <Notify active={props.active}/>}*/}
        <SwipeableViews swipeable={swipeable} onSwitching={onSwitching} animated={isAnimated} lazyLoad={true} index={activeIndex}
                        onChangeIndex={_onChange}>
            {children}
        </SwipeableViews>
    </section>
}
