import React, {Component} from 'react';


export default ({style, fill, width, className, viewBox, firstColor, secondColor}) => {
    return <svg
        width={128}
        style={style}
        height={128}
        viewBox={'0 0 128 128'}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g transform="translate(0, -300)">
            <linearGradient id="home-linear-gradient" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="35.5" y2="8.003">
                <stop offset="0" stopColor="#ff3345"/>
                <stop offset="1" stopColor="#ff657a"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-2" gradientUnits="userSpaceOnUse" x1="63.967" x2="63.967" y1="25.375"
                            y2="53.51">
                <stop offset="0" stopColor="#57ad9d"/>
                <stop offset=".181" stopColor="#53a39b"/>
                <stop offset=".49" stopColor="#4a8794"/>
                <stop offset=".889" stopColor="#3a5b89"/>
                <stop offset=".989" stopColor="#364f86"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-3" gradientUnits="userSpaceOnUse" x1="63.967" x2="63.967" y1="23.5"
                            y2="48.259">
                <stop offset="0" stopColor="#efe8dd"/>
                <stop offset=".184" stopColor="#efe0cb"/>
                <stop offset=".444" stopColor="#efd8b8"/>
                <stop offset=".712" stopColor="#efd3ad"/>
                <stop offset="1" stopColor="#efd1a9"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-4" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="103.25"
                            y2="117.395">
                <stop offset="0" stopColor="#ff9a77"/>
                <stop offset="1" stopColor="#ffcd9f"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-5" x1="64" x2="64" xlinkHref="#home-linear-gradient-3" y1="103.917"
                            y2="98.831"/>
            <linearGradient id="home-linear-gradient-6" gradientUnits="userSpaceOnUse" x1="122.542" x2="80.869" y1="57.394"
                            y2="57.394">
                <stop offset="0" stopColor="#ffe781"/>
                <stop offset=".632" stopColor="#ffb94f"/>
                <stop offset="1" stopColor="#ffa236"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-7" x1="20.087" x2="47.946" xlinkHref="#home-linear-gradient-6" y1="57.5"
                            y2="57.5"/>
            <linearGradient id="home-linear-gradient-8" x1="63.967" x2="63.967" xlinkHref="#home-linear-gradient" y1="54.65"
                            y2="46.391"/>
            <linearGradient id="home-linear-gradient-9" gradientUnits="userSpaceOnUse" x1="63.967" x2="63.967" y1="36.938"
                            y2="74.381">
                <stop offset="0" stopColor="#efe8dd"/>
                <stop offset="1" stopColor="#ffcd9f"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-10" x1="63.967" x2="63.967" xlinkHref="#home-linear-gradient-6" y1="48.063"
                            y2="60.135"/>
            <linearGradient id="home-linear-gradient-11" gradientUnits="userSpaceOnUse" x1="98.228" x2="85.603" y1="71.414"
                            y2="53.539">
                <stop offset="0" stopColor="#efe8dd"/>
                <stop offset="1" stopColor="#efd1a9"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-12" gradientTransform="matrix(-1 0 0 1 127.934 0)" x1="98.228"
                            x2="85.603"
                            xlinkHref="#home-linear-gradient-11" y1="71.414" y2="53.539"/>
            <linearGradient id="home-linear-gradient-13" x1="64" x2="64" xlinkHref="#home-linear-gradient" y1="90.375"
                            y2="102.891"/>
            <linearGradient id="home-linear-gradient-14" gradientUnits="userSpaceOnUse" x1="64" x2="64" y1="76.917"
                            y2="67.198">
                <stop offset="0" stopColor="#57ad9d"/>
                <stop offset=".242" stopColor="#55b2a1"/>
                <stop offset=".513" stopColor="#4dbfac"/>
                <stop offset=".797" stopColor="#41d5bf"/>
                <stop offset="1" stopColor="#36ead1"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-15" x1="54.917" x2="63.334" xlinkHref="#home-linear-gradient-2" y1="70.982"
                            y2="77.774"/>
            <linearGradient id="home-linear-gradient-16" x1="64" x2="64" xlinkHref="#home-linear-gradient-3" y1="76.833"
                            y2="94.538"/>
            <linearGradient id="home-linear-gradient-17" gradientUnits="userSpaceOnUse" x1="87.33" x2="87.33" y1="71.125"
                            y2="57.592">
                <stop offset="0" stopColor="#ff3345"/>
                <stop offset="1" stopColor="#ff9c7a"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-18" gradientUnits="userSpaceOnUse" x1="87.33" x2="87.33" y1="56.625"
                            y2="71.439">
                <stop offset="0" stopColor="#683051"/>
                <stop offset="1" stopColor="#e52c48"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-19" x1="73.515" x2="64.181" xlinkHref="#home-linear-gradient-2" y1="71.748"
                            y2="76.248"/>
            <linearGradient id="home-linear-gradient-20" gradientUnits="userSpaceOnUse" x1="76.824" x2="104.49" y1="70.495"
                            y2="60.745">
                <stop offset="0" stopColor="#683051"/>
                <stop offset="1" stopColor="#40d3a4"/>
            </linearGradient>
            <linearGradient id="home-linear-gradient-21" gradientTransform="matrix(-1 0 0 1 127.934 0)" x1="87.33" x2="87.33"
                            xlinkHref="#home-linear-gradient-17" y2="57.592"/>
            <linearGradient id="home-linear-gradient-22" gradientTransform="matrix(-1 0 0 1 127.934 0)" x1="87.33" x2="87.33"
                            xlinkHref="#home-linear-gradient-18" y1="56.625" y2="71.439"/>
            <linearGradient id="home-linear-gradient-23" gradientUnits="userSpaceOnUse" x1="19.253" x2="52.821" y1="63.841"
                            y2="63.841">
                <stop offset="0" stopColor="#57ad9d"/>
                <stop offset=".191" stopColor="#55a89a"/>
                <stop offset=".405" stopColor="#519b93"/>
                <stop offset=".63" stopColor="#498586"/>
                <stop offset=".861" stopColor="#3e6575"/>
                <stop offset="1" stopColor="#364f68"/>
            </linearGradient>
            <path
                d="m105.4 27.05c-1.478-10.354-11.19-7.394-11.313-10.409-.195-4.808-3.254-11.18-5.966-9.56-4.092 2.445-13.181.4-20.044 7.108a5.867 5.867 0 0 1 -8.158 0c-6.861-6.706-15.95-4.663-20.042-7.108-2.712-1.62-5.771 4.752-5.966 9.56-.123 3.015-9.835.055-11.313 10.409v30.45h82.8z"
                fill="url(#home-linear-gradient)"/>
            <path
                d="m95.25 55.261c0 16.16-14.006 29.26-31.283 29.26s-31.283-13.1-31.283-29.26c0-11.12 14.811-24.105 24.051-31.123a11.949 11.949 0 0 1 14.465 0c9.239 7.018 24.05 20.003 24.05 31.123z"
                fill="url(#home-linear-gradient-2)"/>
            <path
                d="m63.967 86.021c-18.076 0-32.782-13.8-32.782-30.76 0-11.239 13.4-23.778 24.642-32.318a13.5 13.5 0 0 1 16.279 0c11.243 8.539 24.644 21.078 24.644 32.318 0 16.961-14.706 30.76-32.783 30.76zm0-62.811a10.44 10.44 0 0 0 -6.325 2.122c-8.761 6.655-23.457 19.393-23.457 29.929 0 15.307 13.36 27.76 29.782 27.76s29.783-12.453 29.783-27.76c0-10.537-14.7-23.274-23.458-29.929a10.442 10.442 0 0 0 -6.325-2.122z"
                fill="url(#home-linear-gradient-3)"/>
            <path
                d="m98.676 107.993c0 9.357-5.956 16.666-32.987 11.438a8.886 8.886 0 0 0 -3.378 0c-27.031 5.228-32.987-2.081-32.987-11.438v-53.243h69.352z"
                fill="url(#home-linear-gradient-4)"/>
            <path
                d="m42.53 77.432v23.287a10.912 10.912 0 0 0 1.3 1.347c3.093 2.588 11.726 2.234 20.17 2.234s17.077.35 20.166-2.238a10.912 10.912 0 0 0 1.3-1.347v-23.283z"
                fill="url(#home-linear-gradient-5)"/>
            <path
                d="m113.519 56.382a11.943 11.943 0 0 0 .643 13.379c5.859 7.833-.494 15.574-6.443 17-21.375 5.108-34.648-7.761-36.797-12.991-2.3-5.607-6.049-13.434 2.779-27.53 6.575-10.5 17.607-19.451 28.93-19.387 15.859.09 18.869 16.534 10.888 29.529z"
                fill="url(#home-linear-gradient-6)"/>
            <path
                d="m14.481 56.488a11.944 11.944 0 0 1 -.643 13.38c-5.859 7.832.494 15.573 6.443 17 21.375 5.111 34.648-7.759 36.8-12.988 2.3-5.606 6.049-13.434-2.779-27.529-6.575-10.5-17.607-19.452-28.93-19.388-15.862.086-18.872 16.53-10.891 29.525z"
                fill="url(#home-linear-gradient-7)"/>
            <circle cx="63.967" cy="53.239" fill="url(#home-linear-gradient-8)" r="12.705"/>
            <path
                d="m63.967 67.673a14.434 14.434 0 1 1 14.433-14.434 14.45 14.45 0 0 1 -14.433 14.434zm0-25.868a11.434 11.434 0 1 0 11.433 11.434 11.447 11.447 0 0 0 -11.433-11.434z"
                fill="url(#home-linear-gradient-9)"/>
            <circle cx="63.967" cy="53.239" fill="#fff" r="4.98"/>
            <path
                d="m63.967 59.718a6.48 6.48 0 1 1 6.479-6.479 6.487 6.487 0 0 1 -6.479 6.479zm0-9.959a3.48 3.48 0 1 0 3.479 3.48 3.484 3.484 0 0 0 -3.479-3.48z"
                fill="url(#home-linear-gradient-10)"/>
            <path
                d="m77.7 69.022a6.035 6.035 0 0 1 -.085-6.614c1.991-3.676 12.822-12.98 22.36-11.944a5.567 5.567 0 0 1 4.368 3.035c2.264 4.5 6.008 14.555-1.79 20.739-4.859 3.853-17.324 5.848-24.853-5.216z"
                fill="url(#home-linear-gradient-11)"/>
            <path
                d="m50.235 69.022a6.037 6.037 0 0 0 .086-6.614c-1.991-3.676-12.821-12.98-22.361-11.944a5.568 5.568 0 0 0 -4.368 3.036c-2.264 4.5-6.007 14.555 1.79 20.739 4.858 3.852 17.324 5.847 24.853-5.217z"
                fill="url(#home-linear-gradient-12)"/>
            <path d="m47.522 82.625v12.747c2.517 3.393 9.153 2.559 16.478 2.559s13.961.834 16.478-2.559v-12.747z"
                  fill="url(#home-linear-gradient-13)"/>
            <path d="m64 66.025c-7.615 0-13.04 5.173-12.53 12.669h25.06c.51-7.494-4.915-12.669-12.53-12.669z"
                  fill="url(#home-linear-gradient-14)"/>
            <path
                d="m60.637 76.035a1.5 1.5 0 0 1 -1.411-.991 1.081 1.081 0 0 0 -.681-.658 1.971 1.971 0 0 0 -.971-.124 1.5 1.5 0 0 1 -2.835-.685 2.431 2.431 0 0 1 2.281-2.263 4.508 4.508 0 0 1 5.028 2.711 1.5 1.5 0 0 1 -1.411 2.01z"
                fill="url(#home-linear-gradient-15)"/>
            <path
                d="m13.634 92.711c3.75-4.7 11.048-4.993 16.046-6.04 9.691-2.029 15.238-9.239 34.32-9.239s24.629 7.21 34.32 9.239c5 1.047 12.3 1.339 16.046 6.04 4.583 5.746.935 17.561-15.69 15.282-16.448-2.256-15.112-19.193-34.676-19.193s-18.228 16.934-34.676 19.19c-16.624 2.282-20.273-9.533-15.69-15.279z"
                fill="url(#home-linear-gradient-16)"/>
            <path
                d="m22.851 53.753a9.254 9.254 0 0 1 -6.1-2.682 1.5 1.5 0 1 1 2.065-2.171c1.517 1.44 3.932 2.49 5.659 1.394a1.5 1.5 0 0 1 1.609 2.531 5.934 5.934 0 0 1 -3.233.928z"
                fill="#ffa236"/>
            <path
                d="m105.149 53.753a5.934 5.934 0 0 1 -3.233-.932 1.5 1.5 0 1 1 1.609-2.531c1.725 1.095 4.142.045 5.659-1.394a1.5 1.5 0 1 1 2.066 2.175 9.254 9.254 0 0 1 -6.101 2.682z"
                fill="#ffa236"/>
            <circle cx="87.33" cy="64.543" fill="url(#home-linear-gradient-17)" r="8.468"/>
            <path
                d="m87.33 74.51a9.968 9.968 0 1 1 9.97-9.968 9.979 9.979 0 0 1 -9.97 9.968zm0-16.935a6.968 6.968 0 1 0 6.97 6.967 6.976 6.976 0 0 0 -6.97-6.967z"
                fill="url(#home-linear-gradient-18)"/>
            <circle cx="87.33" cy="64.543" fill="#e52c48" r="2.978"/>
            <circle cx="87.33" cy="64.543" fill="#fff" r=".991"/>
            <path
                d="m67.363 76.035a1.5 1.5 0 0 1 -1.411-2.01 4.5 4.5 0 0 1 5.028-2.711 2.431 2.431 0 0 1 2.281 2.263 1.5 1.5 0 0 1 -2.835.685 1.969 1.969 0 0 0 -.971.124 1.081 1.081 0 0 0 -.681.658 1.5 1.5 0 0 1 -1.411.991z"
                fill="url(#home-linear-gradient-19)"/>
            <path
                d="m92.737 78.8a19.74 19.74 0 0 1 -2.99-.227 19.357 19.357 0 0 1 -13.288-8.7 7.557 7.557 0 0 1 -.165-8.172c2.392-4.417 13.874-13.8 23.843-12.721a7.045 7.045 0 0 1 5.546 3.851c2.254 4.481 6.553 15.649-2.2 22.59a17.883 17.883 0 0 1 -10.746 3.379zm5.732-26.912c-8.555 0-17.963 8.333-19.536 11.239a4.575 4.575 0 0 0 .006 5.054 16.429 16.429 0 0 0 11.261 7.419 15.558 15.558 0 0 0 11.422-2.54c6.771-5.37 3.773-14.14 1.382-18.89a4.054 4.054 0 0 0 -3.191-2.218 12.491 12.491 0 0 0 -1.344-.068z"
                fill="url(#home-linear-gradient-20)"/>
            <circle cx="40.604" cy="64.543" fill="url(#home-linear-gradient-21)" r="8.468"/>
            <path
                d="m30.636 64.542a9.968 9.968 0 1 1 9.964 9.968 9.979 9.979 0 0 1 -9.964-9.968zm3 0a6.968 6.968 0 1 0 6.964-6.967 6.975 6.975 0 0 0 -6.964 6.967z"
                fill="url(#home-linear-gradient-22)"/>
            <circle cx="40.604" cy="64.543" fill="#e52c48" r="2.978"/>
            <circle cx="40.604" cy="64.543" fill="#fff" r=".991"/>
            <path
                d="m35.263 78.8a17.883 17.883 0 0 1 -10.747-3.382c-8.752-6.941-4.454-18.109-2.2-22.589a7.044 7.044 0 0 1 5.546-3.852c9.963-1.082 21.449 8.3 23.843 12.721a7.557 7.557 0 0 1 -.165 8.172 19.359 19.359 0 0 1 -13.288 8.7 19.74 19.74 0 0 1 -2.989.23zm-5.732-26.916a12.537 12.537 0 0 0 -1.344.071 4.053 4.053 0 0 0 -3.187 2.219c-2.391 4.749-5.389 13.519 1.382 18.889a15.557 15.557 0 0 0 11.418 2.537 16.429 16.429 0 0 0 11.26-7.425 4.575 4.575 0 0 0 .006-5.054c-1.572-2.904-10.981-11.237-19.535-11.237z"
                fill="url(#home-linear-gradient-23)"/>
        </g>
    </svg>
}











